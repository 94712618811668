import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { W2ClientMessageType } from "../../../shared/wordsquared/w2Messages";
import {
  pendingColorAtom,
  playTilesSelector,
  usernameAtom,
} from "../recoil/game";
import { w2Socket } from "../w2Websocket";

export const useSubmitPlay = () => {
  const playTiles = useRecoilValue(playTilesSelector);
  const user = useRecoilValue(usernameAtom);
  const color = useRecoilValue(pendingColorAtom);
  return useCallback(() => {
    if (!w2Socket) {
      console.error("Websocket does not exist");
      return;
    } else if (w2Socket.readyState !== WebSocket.OPEN) {
      console.warn("Socket is not yet open");
      return;
    }
    if (!playTiles.length) {
      return;
    }
    const message = {
      type: W2ClientMessageType.SUBMIT_PLAY,
      tiles: playTiles,
      user,
      color,
    };
    w2Socket.send(JSON.stringify(message));
  }, [playTiles, user, color]);
};
