import { Copy } from "@styled-icons/fa-regular";
import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { FRONTEND_URL } from "shared/constants";
import styled, { css, keyframes } from "styled-components";
import { gameIdAtom } from "./recoil";

const Container = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  border: 1px solid black;
`;

const LinkBox = styled.div`
  padding: 8px;
  background-color: white;
  border-radius: 8px 0 0 8px;
`;

const quickFlashFrames = keyframes`
  from {
    background-color: lightgreen;
  }
  to {
    background-color: lightgray;
  }
`;

const quickFlash = css`
  animation: ${quickFlashFrames} 0.5s ease-in;
`;

const CopyIconContainer = styled.div<{ shouldFlash: boolean }>`
  padding: 8px;
  background-color: lightgray;
  ${props => props.shouldFlash && quickFlash}
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
`;

const ShareLink = () => {
  const id = useRecoilValue(gameIdAtom);
  const link = `${FRONTEND_URL}/wordery/${id}`;
  const [clicks, setClicks] = useState(0);
  const copy = useCallback(() => {
    setClicks(clicks + 1);
    navigator.clipboard.writeText(link);
  }, [clicks, setClicks, link]);
  return (
    <Container onClick={copy}>
      <LinkBox>{link}</LinkBox>
      <CopyIconContainer key={clicks} shouldFlash={clicks > 0}>
        <Copy size={18} />
      </CopyIconContainer>
    </Container>
  );
};

export default ShareLink;
