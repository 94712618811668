import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from "recoil";
import { notifsAtom, usernameAtom } from "./recoil/game";
import { ChangeEvent, FormEvent, useContext, useEffect } from "react";
import { transientErrorStyle, useTransientError } from "../utils/transientErrorStyle";
import { last } from "lodash";
import { Notif, NotifType } from "./notifs";
import { W2InvalidPlayType } from "../../shared/wordsquared/w2Messages";
import { useFocusCanvas, UsernameRefContext } from './hooks/useFocus';

const UsernameInput = styled.input.attrs({ type: "text" })`
/* ${transientErrorStyle(
  { property: "border", style: "2px solid red" },
)} */
`;

const UsernameInputForm = styled.form`
  padding: 5px;
  border: 5px solid transparent;
  ${transientErrorStyle({ property: "border", style: "5px solid red" })}
`;

const UsernameForm = () => {
  const [username, setUsername] = useRecoilState(usernameAtom);
  const { errorClass, triggerError } = useTransientError();
  const usernameRef = useContext(UsernameRefContext);

  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const notifs = useRecoilValue(notifsAtom);
  useEffect(() => {
    if (!notifs.length) {
      return;
    }
    const notif = last(notifs) as Notif;
    if (notif.type === NotifType.INVALID_PLAY && notif.reason === W2InvalidPlayType.ILLEGAL_USERNAME) {
      triggerError();
    }
  }, [notifs, triggerError]);

  const focusCanvas = useFocusCanvas();
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    focusCanvas();
  };

  return <div>
    <UsernameInputForm className={errorClass} onSubmit={onSubmit}>
      Username (must be a legal word):&nbsp;
      <UsernameInput ref={usernameRef} className={errorClass} value={username} onChange={onChangeUsername} />
    </UsernameInputForm>
  </div>;

};

export default UsernameForm;