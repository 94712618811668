import { useCallback, useState } from "react";
import { css } from "styled-components";

export const ERROR_FADE_IN_MS = 150;
export const ERROR_FADE_OUT_MS = 1500;

type Options = {
  property: string,
  style: string,
  ease?: string,
}[];

export const transientErrorStyle = (...options: Options) => {
  const transitionsOut = options.map(({ property, ease = "" }) => `${property} ${ERROR_FADE_OUT_MS}ms ${ease}`);
  const transitionsIn = options.map(({ property, ease = "" }) => `${property} ${ERROR_FADE_IN_MS}ms ${ease}`);
  const errorProps = options.map(({ property, style }) => `${property}: ${style};`);
  return css`
      // Transition out of the error state is gradual
    transition: ${transitionsOut.join(',')};

    &.error {
      ${errorProps.join('\n')}
      // Transition into the error state is instantaneous
      transition: ${transitionsIn.join(',')};
    }
  `;
};

export const useTransientError = () => {
  const [hasError, setHasError] = useState<boolean>(false);
  const errorClass = hasError ? "error" : "";
  const triggerError = useCallback(() => {
    // Quickly toggle the error state to trigger the transition
    setHasError(true);
    setTimeout(() => {
      setHasError(false);
    }, ERROR_FADE_IN_MS);
  }, []);
  return { errorClass, triggerError };
};;;;;;;
