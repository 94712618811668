import { useCallback, useEffect, useMemo, useState } from "react";
import Background from "src/components/Background";
import setupWebsocket, { useWebsocket } from "src/utils/websocket/setup";
import styled from "styled-components";
import { WSTunnelClientMessageType, WSTunnelServerMessage, WSTunnelServerMessageType } from "../../shared/websockets/tunnel";

const Light = styled.div<{ on: boolean; }>`
  width: 200px;
  height: 200px;
  border-radius: 50% 50% 0 0;
  background-color: ${({ on }) => on ? 'white' : 'black'};
  transition: background-color 150ms;
`;

const Tunnel = () => {
  const [light, setLight] = useState(true);

  const socket = useWebsocket();
  useEffect(
    () => {
      socket.addEventListener("message", e => {
        const message: WSTunnelServerMessage = JSON.parse(e.data);
        switch (message.type) {
          case WSTunnelServerMessageType.LIGHT:
            setLight(message.value);
            break;
        }
      });
      socket.addEventListener("error", console.error);
    }, [socket]
  );

  const onKeyUp = useCallback((ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    console.log("🚀 / window.addEventListener / ev", ev);

    const { key } = ev;
    const code = key.charCodeAt(0);
    if (code < 'a'.charCodeAt(0) || code > 'z'.charCodeAt(0)) {
      return;
    }
    const message = JSON.stringify({
      type: WSTunnelClientMessageType.LETTER,
      letter: key,
    });
    socket.send(message);
  }, [socket]);

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, [onKeyUp]);

  return (
    <Background color="black">
      <div style={{ width: '100%', marginTop: '64px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Light on={light} />
      </div>
    </Background>
  );
};

export default Tunnel;
