import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import pluralize from "pluralize";
import { reverse } from "lodash/fp";
import { fetchFromApi } from "src/utils/api";
import styled from "styled-components";

const DEFAULT_LENGTH = 5;

enum PuzzleType {
  "Andagram",
}

interface AndagramPuzzle {
  type: PuzzleType.Andagram;
  word: string;
  letter: string;
  solutions: string[];
}

interface HistoryEntry {
  puzzle: AndagramPuzzle;
  guesses: string[];
  success: boolean;
}

const getRandomPuzzle = async (length: number): Promise<AndagramPuzzle> => {
  const res = await fetchFromApi(`/anagrams/andagram?length=${length}`, {
    method: "GET",
  });
  return res.json();
};

const WordLink = styled.a`
  color: inherit;
`;

const ClickableWord: React.FC<{ word: string }> = ({ word }) => {
  return (
    <WordLink
      href={`https://google.com/search?q=define:${word}`}
      target="_blank"
    >
      {word}
    </WordLink>
  );
};

const ClickableWordList: React.FC<{ words: string[] }> = ({ words }) => {
  return (
    <>
      {words.map((word, index) => (
        <span key={word}>
          <ClickableWord word={word} />
          {index < words.length - 1 && ", "}
        </span>
      ))}
    </>
  );
};

const History: React.FC<{ history: HistoryEntry[] }> = ({ history }) => {
  if (!history.length) return null;
  return (
    <div>
      <u>History</u>
      <div>
        Correct: {history.filter(entry => entry.success).length} /{" "}
        {history.length}{" "}
      </div>
      <div>
        Correct streak:{" "}
        {_.takeRightWhile(history, entry => entry.success).length}
      </div>
      <div>
        One-guess streak:{" "}
        {
          _.takeRightWhile(
            history,
            entry => entry.success && entry.guesses.length === 1
          ).length
        }
      </div>
      {reverse(history).map(
        ({ puzzle: { word, letter, solutions }, guesses, success }, index) => {
          if (success) {
            const correctGuess = _.last(guesses) as string;
            return (
              <div key={word + letter + index}>
                <ClickableWord word={word} /> + {letter} ={" "}
                <ClickableWord word={correctGuess} /> ({guesses.length}{" "}
                {pluralize("guess", guesses.length)})
                {solutions.length > 1 && (
                  <ClickableWordList words={_.without(guesses, correctGuess)} />
                )}
              </div>
            );
          }
          return (
            <div
              key={word + letter + index}
              style={{ color: "mediumvioletred" }}
            >
              <ClickableWord word={word} /> + {letter} ={" "}
              <ClickableWordList words={solutions} />
            </div>
          );
        }
      )}
    </div>
  );
};

const Anagrams = () => {
  const [length, setLength] = useState(DEFAULT_LENGTH);
  const [puzzle, setPuzzle] = useState<AndagramPuzzle | null>(null);
  const [submission, setSubmission] = useState("");
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [guesses, setGuesses] = useState<string[]>([]);
  const submissionRef = useRef<HTMLInputElement>(null);
  const makeNewPuzzle = useCallback(async () => {
    let newPuzzle: AndagramPuzzle;
    setPuzzle(null);
    do {
      newPuzzle = await getRandomPuzzle(length);
      // eslint-disable-next-line no-loop-func
    } while (history.find(entry => _.isEqual(entry, newPuzzle)));
    setPuzzle(newPuzzle);
    setGuesses([]);
  }, [history, length]);
  useEffect(() => {
    if (history.length === 0 && !puzzle) {
      makeNewPuzzle();
    }
  }, [makeNewPuzzle, puzzle, history]);
  const onSetLength = useCallback(({ target: { value } }) => {
    const newLength = Number(value);
    if (_.isInteger(newLength) && 2 <= newLength && newLength <= 14) {
      setLength(newLength);
      setPuzzle(null);
    }
  }, []);
  const onSubmitAnagram = useCallback(
    event => {
      event.preventDefault();
      if (!puzzle) return;
      const guess = submission.toUpperCase();
      if (puzzle?.solutions.includes(guess)) {
        setHistory([
          ...history,
          {
            puzzle,
            guesses: [...guesses, guess],
            success: true,
          },
        ]);
        makeNewPuzzle();
      } else if (guess.length === length + 1) {
        setGuesses([...guesses, guess]);
      }
      setSubmission("");
    },
    [history, puzzle, guesses, submission, length, makeNewPuzzle]
  );
  const onGiveUp = useCallback(() => {
    if (!puzzle) return;
    setHistory([
      ...history,
      {
        puzzle,
        guesses,
        success: false,
      },
    ]);
    makeNewPuzzle();
    if (submissionRef.current) {
      submissionRef.current?.focus();
    }
  }, [puzzle, history, guesses, makeNewPuzzle]);
  return (
    <div>
      <h1>Anagram Trainer</h1>
      <div style={{ fontFamily: "Roboto Mono, monospace" }}>
        <div>
          <div>
            {!puzzle ? (
              <div>Loading&hellip;</div>
            ) : (
              <span>
                {puzzle.word} + {puzzle.letter}
              </span>
            )}
          </div>
          <form onSubmit={onSubmitAnagram}>
            <label>Submit:&nbsp;</label>
            <input
              ref={submissionRef}
              value={submission}
              onChange={e => setSubmission(e.target.value)}
              spellCheck={false}
            />
          </form>
          {guesses.length > 0 && <div>Guesses: {guesses.join(", ")}</div>}
          <button onClick={onGiveUp}>Give up</button>
        </div>
        <br />
        <div>
          <u>Options</u>
          <form onSubmit={e => e.preventDefault()}>
            <label>Length: </label>
            <input
              type="number"
              value={length}
              onChange={onSetLength}
              style={{ width: "30px" }}
            />
          </form>
        </div>
        <br />
        <History history={history} />
      </div>
    </div>
  );
};

export default Anagrams;
