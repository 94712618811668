const CHARACTER_IMAGE_MAP = {
  Bowser: "BowserHeadSSBM.png",
  "Captain Falcon": "CaptainFalconHeadSSBM.png",
  "Donkey Kong": "DonkeyKongHeadSSBM.png",
  "Dr. Mario": "DrMarioHeadSSBM.png",
  Falco: "FalcoHeadSSBM.png",
  Fox: "FoxHeadSSBM.png",
  Ganondorf: "GanondorfHeadSSBM.png",
  "Ice Climbers": "IceClimbersHeadSSBM.png",
  Jigglypuff: "JigglypuffHeadSSBM.png",
  Kirby: "KirbyHeadSSBM.png",
  Link: "LinkHeadSSBM.png",
  Luigi: "LuigiHeadSSBM.png",
  Mario: "MarioHeadSSBM.png",
  Marth: "MarthHeadSSBM.png",
  Mewtwo: "MewtwoHeadSSBM.png",
  "Mr. Game & Watch": "MrGame&WatchHeadSSBM.png",
  Ness: "NessHeadSSBM.png",
  Peach: "PeachHeadSSBM.png",
  Pichu: "PichuHeadSSBM.png",
  Pikachu: "PikachuHeadSSBM.png",
  Roy: "RoyHeadSSBM.png",
  Samus: "SamusHeadSSBM.png",
  Sheik: "SheikHeadSSBM.png",
  Yoshi: "YoshiHeadSSBM.png",
  "Young Link": "YoungLinkHeadSSBM.png",
  Zelda: "ZeldaHeadSSBM.png",
};

interface IconProps {
  character: string;
  size?: number;
}

const HeadIcon: React.FC<IconProps> = ({ character, size }) => {
  const image = `head_icons/${CHARACTER_IMAGE_MAP[character]}`;
  if (!image) return null;

  return <img src={image} alt={character} style={{ height: size }} />;
};

export default HeadIcon;
