const FPS = 60;

const hasTextNode = el => {
  return [...el.childNodes].some(
    node => node.nodeType === 3 && node.textContent.trim().length > 0
  );
};

let lastInterval;

export const startRotation = () => {
  if (lastInterval) {
    clearInterval(lastInterval);
    lastInterval = null;
    return;
  }
  let els = [...document.getElementsByTagName("*")];
  els = els.filter(el => hasTextNode(el) || !el.hasChildNodes);
  const angles = els.map(() => 0);
  const deltas = els.map(() => 0);
  const accels = els.map(() => (0.1 + Math.random()) / FPS / 10);

  lastInterval = setInterval(() => {
    els.forEach((el, idx) => {
      deltas[idx] += accels[idx];
      angles[idx] += deltas[idx];
      if (el) {
        /* @ts-ignore */
        el.style.transform = `rotateY(${angles[idx]}deg)`;
      }
    });
  }, 1000 / FPS);
  return lastInterval;
};
