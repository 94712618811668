import { selector } from "recoil";
import { LeaderboardEntry } from "shared/wordery/types";
import { fetchFromApi } from "src/utils/api";
import { cacheIdAtom, useBreakCache } from "src/utils/recoilCache";
import { gameIdAtom } from "./gameState";

export const leaderboardSelector = selector<LeaderboardEntry[]>({
  key: "wordery/leaderboard",
  get: async ({ get }) => {
    get(cacheIdAtom);
    const id = get(gameIdAtom);
    if (!id) {
      return [];
    }
    const res = await fetchFromApi(`/wordery/scores/${id}`);
    const fetchedScores: LeaderboardEntry[] = await res.json();
    return fetchedScores;
  },
});

export const useUpdateLeaderboard = useBreakCache;
