import styled from "styled-components";

const WIDTH_BREAKPOINT = 853;

const Container = styled.div`
  @media (max-width: ${WIDTH_BREAKPOINT}px) {
    width: calc(90% - 64px);
    padding: 32px;
  }
  @media (min-width: ${WIDTH_BREAKPOINT + 1}px) {
    width: 640px;
    padding: 64px;
  }
  flex: 1;
  margin: 0 auto;
  border-left: 3px double black;
  border-right: 3px double black;
  background-color: #eee;
`;

export default Container;
