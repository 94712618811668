import { useCallback } from "react";
import { atom, selector, useRecoilValue, useSetRecoilState } from "recoil";
import { getGridIndex, Sparse2DArray } from "../../../shared/wordsquared/gridUtils";
import { BoardTile, Coords, CursorDirection, GridAreaType, Letter, PlayTile } from "../../../shared/wordsquared/types";
import { tileToGridCoords } from "../gridCanvasMath";
import { Notif } from "../notifs";
import { cameraLocationAtom, gridSquareSizeSelector } from "./camera";

export const playerIdAtom = atom<string | null>({
  key: 'wordsquared/player-id',
  default: null,
});

export const gridAreasAtom = atom<GridAreaType[]>({
  key: 'wordsquared/grid-areas',
  default: []
});

export const gridTilesS2ASelector = selector<Sparse2DArray<BoardTile>>({
  key: 'wordsquared/grid-tiles-s2a',
  get: ({ get }) => {
    const s2a = new Sparse2DArray<BoardTile>();
    const gridAreas = get(gridAreasAtom);
    gridAreas.forEach(gridArea => {
      gridArea.tiles.forEach((row, rowIndex) => {
        row.forEach((tile, tileIndex) => {
          if (tile !== null) {
            const [x, y] = getGridIndex(gridArea.areaX, gridArea.areaY, tileIndex, rowIndex);
            s2a.set(x, y, tile);
          }
        });
      });
    });
    return s2a;
  }
});

export const cursorLocationAtomW2 = atom<Coords>({
  key: 'wordsquared/cursor-location',
  default: [0, 0]
});

export const selectedTileSelector = selector<BoardTile | undefined>({
  key: 'wordsquared/selected-tile',
  get: ({ get }) => {
    const gridTiles = get(gridTilesS2ASelector);
    const [x, y] = get(cursorLocationAtomW2);
    return gridTiles.get(x, y);
  }
});

export const useCenterCursorLocation = () => {
  const setCameraLocation = useSetRecoilState(cameraLocationAtom);
  const cursorLocation = useRecoilValue(cursorLocationAtomW2);
  const squareSize = useRecoilValue(gridSquareSizeSelector);
  const cameraCenter = tileToGridCoords(cursorLocation, squareSize);
  return useCallback(
    () => {
      setCameraLocation(cameraCenter);
    }, [setCameraLocation, cameraCenter]
  );
};

export const cursorDirectionAtomW2 = atom<CursorDirection>({
  key: 'wordsquared/cursor-direction',
  default: CursorDirection.RIGHT
});

export const useToggleCursorDirection = () => {
  const setCursorDirection = useSetRecoilState(cursorDirectionAtomW2);
  return useCallback(() => setCursorDirection(dir =>
    dir === CursorDirection.DOWN
      ? CursorDirection.RIGHT
      : CursorDirection.DOWN
  ), [setCursorDirection]);
};

export const pendingMoveAtom = atom<Sparse2DArray<Letter>>({
  key: 'wordsquared/prelim-move',
  default: new Sparse2DArray()
});

export const deleteCursorTargetSelector = selector({
  key: 'w2/first-typed-pending-tile',
  get: ({ get }) => {
    const pendingMove = get(pendingMoveAtom);
    if (!pendingMove.isEmpty()) {
      // This relies on the keys being returned in the order they were added
      return pendingMove.keys()[0];
    }
    return null;
  }
});

export const playTilesSelector = selector<PlayTile[]>({
  key: 'wordsquared/play-tiles',
  get: ({ get }) => {
    const pendingMove = get(pendingMoveAtom);
    return pendingMove.map((letter, [x, y]) => ({ letter, x, y }));
  }
});

export const usernameAtom = atom<string>({
  key: 'w2/username',
  default: '',
});

export const pendingColorAtom = atom<string>({
  key: 'w2/pending-color',
  default: "#602020",
});

export const notifsAtom = atom<Notif[]>({
  key: 'w2/notifs',
  default: []
});

export const baseRackAtom = atom<Letter[]>({
  key: 'w2/base-rack',
  default: new Array(8).fill(null),
});

export const currentRackAtom = atom<(Letter | null)[]>({
  key: 'w2/current-rack',
  default: new Array(8).fill(null),
});

export const useResetCurrentRack = () => {
  const baseRack = useRecoilValue(baseRackAtom);
  const setCurrentRackAtom = useSetRecoilState(currentRackAtom);
  return useCallback(() => {
    setCurrentRackAtom(baseRack);
  }, [baseRack, setCurrentRackAtom]);
};