import { equals } from "lodash/fp";
import { useRecoilValue } from "recoil";
import {
  CursorDirection,
  cursorDirectionAtom,
  selectedCellAtom,
} from "./recoil";
import {
  ArrowAltCircleRight,
  ArrowAltCircleDown,
} from "@styled-icons/fa-regular";
import { Bonus } from "shared/wordery/types";
import styled from "styled-components";

interface BCEProps {
  bonus: Bonus | null;
  row: number;
  col: number;
}

const BonusText = styled.div`
  text-align: center;
  font-size: 0.7em;
`;

const BoardCellEmpty = ({ bonus, row, col }: BCEProps) => {
  const selectedCell = useRecoilValue(selectedCellAtom);
  const cursorDirection = useRecoilValue(cursorDirectionAtom);
  if (equals([row, col], selectedCell)) {
    if (cursorDirection === CursorDirection.DOWN) {
      return <ArrowAltCircleDown size={48} />;
    } else {
      return <ArrowAltCircleRight size={48} />;
    }
  }
  switch (bonus) {
    case Bonus.DoubleLetter:
      return <BonusText>Double Letter</BonusText>;
    case Bonus.DoubleWord:
      return <BonusText>Double Word</BonusText>;
    default:
      return null;
  }
};

export default BoardCellEmpty;
