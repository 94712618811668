import { memo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ReactTitle } from "react-meta-tags";

import Loading from "src/components/Loading";
import Title from "src/components/Title";
import styled from "styled-components";
import Background from "../../components/Background";
import Container from "../../components/Container";
import DailyHeading from "./DailyHeading";
import Game from "./Game";
import GameNav from "./GameNav";
import Leaderboard from "./Leaderboard";
import { gameIdAtom, useLoadGame } from "./recoil";
import ShareLink from "./ShareLink";

const WorderyContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin-bottom: 10px;
  }
`;

const Wordery = () => {
  const { id } = useParams<{ id?: string; }>();
  const loadGame = useLoadGame(id && !isNaN(Number(id)) ? id : "daily");
  const gameId = useRecoilValue(gameIdAtom);
  useEffect(() => {
    if (!gameId) {
      loadGame();
    }
  }, [id, gameId, loadGame]);
  if (!gameId) {
    return <Loading />;
  }
  return (
    <>
      <DailyHeading />
      <Game />
      <GameNav />
      <Leaderboard />
      <ShareLink />
    </>
  );
};

const WorderyPage = () => (
  <Background color="#d4c5b5">
    <ReactTitle title="Wordery | Leon's Website" />
    <WorderyContainer>
      <Title>Wordery</Title>
      <Wordery />
    </WorderyContainer>
  </Background>
);

export default memo(WorderyPage);
