import React, { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import Loading from "src/components/Loading";
import Background from "src/components/Background";
import Container from "src/components/Container";
import Select from "react-select";
import styled from "styled-components";
import ActionButton from "src/components/ActionButton";
import { useBreakCache } from "src/utils/recoilCache";
import { fetchFromApi } from "src/utils/api";
import { allPostsSelector } from "./recoil";
import ReactMde from "react-mde";
import Showdown from "showdown";
import { ReactTitle } from "react-meta-tags";
import "react-mde/lib/styles/css/react-mde-all.css";

const Label = styled.label`
  width: 100%;
  display: grid;
  grid-template-columns: 10% 85%;
  grid-gap: 5%;
`;

const Form = styled.div`
  /* display: flex; */
  /* grid-template-columns: 20% 80%; */
`;

const unwrapped =
  (setter: Function) =>
  ({ target: { value } }) => {
    setter(value);
  };

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
});

const ContentEditor = ({ value, setValue }) => {
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  return (
    <ReactMde
      value={value}
      onChange={setValue}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={markdown =>
        Promise.resolve(converter.makeHtml(markdown))
      }
    />
  );
};

const PostEditor = () => {
  const posts = useRecoilValue(allPostsSelector);
  const reloadPosts = useBreakCache();
  const options = posts.map(post => ({
    label: `${post.id},  /${post.slug}: ${post.title}`,
    value: post,
  }));
  const [id, setId] = useState<null | number>(null);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [listed, setListed] = useState(false);
  const [content, setContent] = useState("");
  const [password, setPassword] = useState("");
  const onChange = useCallback(({ value: post }) => {
    setId(post.id);
    setTitle(post.title);
    setSlug(post.slug);
    setContent(post.content);
    setListed(post.listed);
  }, []);
  const onSubmit = useCallback(async () => {
    const url = id ? `/posts/${id}` : "/posts/new";
    const res = await fetchFromApi(url, {
      method: "POST",
      body: JSON.stringify({
        id,
        title,
        slug,
        content,
        listed,
        password,
      }),
    });
    const post = await res.json();
    setId(post.id);
    reloadPosts();
  }, [id, title, slug, listed, content, password, reloadPosts]);
  return (
    <>
      <ReactTitle title="Post Editor | Leon's Website" />
      <h1>PostEditor</h1>
      <Select options={options} onChange={onChange} />
      <Form>
        <Label>
          <div>ID</div>
          <div>{id || "New post"}</div>
        </Label>
        <Label>
          <div>Title</div>
          <input type="text" value={title} onChange={unwrapped(setTitle)} />
        </Label>
        <Label>
          <div>Slug</div>
          <input type="text" value={slug} onChange={unwrapped(setSlug)} />
        </Label>
        <Label>
          <div>Listed?</div>
          <input
            type="checkbox"
            checked={listed}
            onChange={event => setListed(event.target.checked)}
          />
        </Label>
        <Label>
          <div>Content</div>
          <ContentEditor value={content} setValue={setContent} />
        </Label>
        <Label>
          <div>Password</div>
          <input
            type="password"
            value={password}
            onChange={unwrapped(setPassword)}
          />
        </Label>
      </Form>
      <ActionButton onClick={onSubmit} value="Submit" />
    </>
  );
};

const PostEditorContainer = () => (
  <Background color="gray">
    <Container>
      <React.Suspense fallback={<Loading />}>
        <PostEditor />
      </React.Suspense>
    </Container>
  </Background>
);

export default PostEditorContainer;
