import { getScoreFromBoard } from "shared/wordery/scoring";
import { Rack, WorderyBoard, WorderyGameParams } from "shared/wordery/types";

interface SavedBoard extends WorderyGameParams {
  score: number;
}

export const getSavedBoardKey = (id: number) => `saved-score-${id}`;

export const saveBoard = (id: number, board: WorderyBoard, rack: Rack) => {
  const savedBoard = loadSavedBoard(id);
  const score = getScoreFromBoard(board);
  if (savedBoard && score <= savedBoard.score) {
    return;
  }
  const valueToSave = JSON.stringify({ board, rack, score });
  window.localStorage.setItem(getSavedBoardKey(id), valueToSave);
};

export const loadSavedBoard = (id: number): SavedBoard | null => {
  const savedValue = window.localStorage.getItem(getSavedBoardKey(id));
  if (!savedValue) {
    return null;
  }
  return JSON.parse(savedValue);
};
