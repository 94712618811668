import { last } from "lodash";
import React, { useCallback, useContext, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { W2InvalidPlayType } from "../../shared/wordsquared/w2Messages";
import { transientErrorStyle, useTransientError } from "../utils/transientErrorStyle";
import { getCurrentDimensions } from "./constants";
import { Notif, NotifType } from "./notifs";
import useDraw, { DEFAULT_TILE_COLOR } from "./hooks/useDraw";
import useOnKeyboardInput from "./w2Keyboard";
import { notifsAtom, selectedTileSelector } from "./recoil/game";
import { canvasDimensionsAtom, useOnScrollCanvas } from "./recoil/camera";
import { CanvasRefContext } from "./hooks/useFocus";
import { useCursorClickHandler } from "./hooks/useGridClickHandler";
import { BoardTile } from "../../shared/wordsquared/types";
import useCopyColorAtCursor from "./hooks/useCopyColorAtCursor";

const StyledCanvas = styled.canvas`
  border: 2px solid black;
  border-radius: 5px;
  outline: none;
  ${transientErrorStyle({ property: "border", style: "2px solid red" })}
`;

const SelectedTileInfoContainer = styled.div`
  position: absolute;
  right: 16px;
  bottom: 20px;
  padding: 10px;
  border: 3px solid white;
  border-radius: 4px;
  background-color: rgba(20,20,20,0.9);
  color: #ddd;
  font-family: Consolas;

  div {
    display: flex;
    align-items: center;
    #copybutton{
      color: lightblue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const ColorSample = styled.div<{ color: string; }>`
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  border: 2px solid white;
  background-color: ${({ color }) => color};
`;

const SelectedTileInfo: React.FC<{ tile: BoardTile; }> = ({ tile }) => {
  const copyColor = useCopyColorAtCursor();
  const time = new Date(tile.timestamp);
  return <SelectedTileInfoContainer>
    <div>Player: {tile.user || "Unknown"}</div>
    <div>{time.toLocaleString()}</div>
    <div><span id="copybutton" onClick={copyColor}>Copy color</span>&nbsp;<ColorSample color={tile.color ?? DEFAULT_TILE_COLOR} /> </div>
  </SelectedTileInfoContainer>;
};

const WordsquaredGrid = () => {
  const canvasRef = useContext(CanvasRefContext);
  useOnKeyboardInput(canvasRef);
  const [[width, height], setDimensions] = useRecoilState(canvasDimensionsAtom);
  const { errorClass, triggerError } = useTransientError();
  const notifs = useRecoilValue(notifsAtom);
  const selectedTile = useRecoilValue(selectedTileSelector);

  useEffect(() => {
    canvasRef.current?.focus();
  }, [canvasRef]);

  const draw = useDraw();
  const drawInCanvas = useCallback(
    () => {
      const context = canvasRef.current?.getContext('2d');
      if (context) {
        draw(context);
      }
    },
    [draw, canvasRef],
  );
  useEffect(() => {
    drawInCanvas();
  }, [drawInCanvas, width, height]);

  // Resize the canvas to fit in the window
  useEffect(() => {
    const onResize = () => setDimensions(getCurrentDimensions());
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setDimensions, drawInCanvas]);

  useEffect(() => {
    if (!notifs.length) {
      return;
    }
    const notif = last(notifs) as Notif;
    if (notif.type === NotifType.INVALID_PLAY && notif.reason === W2InvalidPlayType.ILLEGAL_WORD) {
      triggerError();
    }
  }, [notifs, triggerError]);

  const onClick = useCursorClickHandler();
  const onScroll = useOnScrollCanvas();

  return <div style={{ position: 'relative' }}>
    <StyledCanvas
      className={errorClass} tabIndex={0} ref={canvasRef}
      width={width} height={height} onClick={onClick} onWheel={onScroll}
    />
    {selectedTile && <SelectedTileInfo tile={selectedTile} />}
  </div>;

};

export default WordsquaredGrid;