import { useRecoilValue } from "recoil";
import ActionButton from "src/components/ActionButton";
import { gameIdAtom, useLoadGame } from "./recoil";

const GameNav = () => {
  const gameId = useRecoilValue(gameIdAtom);
  const onClickNewGame = useLoadGame("new");
  const onClickPrev = useLoadGame(String(gameId - 1));
  const onClickNext = useLoadGame(String(gameId + 1));
  return (
    <div>
      {gameId > 1 && <ActionButton onClick={onClickPrev} value="< Previous" />}
      <ActionButton onClick={onClickNewGame} value="New Game" />
      <ActionButton onClick={onClickNext} value="Next >" />
    </div>
  );
};

export default GameNav;
