import { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { ChatMessage, WSChatClientMessage, WSChatClientMessageType, WSChatServerMessage, WSChatServerMessageType } from "../../shared/websockets/chat";
import Background from "../components/Background";
import Container from "../components/Container";
// import withDraggableScroll from "../components/withDraggableScroll";
import { useWebsocket } from "../utils/websocket/setup";

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  /* justify-content: flex-end; */
  box-sizing: border-box;
  width: 100%;
  height: 400px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 4px 0;
  overflow-y: scroll;
  background-color: lightgray;

  /* ::-webkit-scrollbar {
    width: 0;
  } */
`;

const Message = styled.div`
  display: flex;
  margin-top: 4px;
`;
interface MessagesProps {
  messages: ChatMessage[];
  className?: string;
}
const Messages = memo(({ messages, className }: MessagesProps) => {
  return <MessagesContainer className={className}>
    {messages.map(message => <Message>
      <b>{message.author}: &nbsp;</b>
      <div>{message.text}</div>
    </Message>)}
  </MessagesContainer>;
});

interface TextBoxProps {
  send: (text: string) => void;
}
const TextBox = ({ send }: TextBoxProps) => {
  const [value, setValue] = useState('');
  const onSubmitForm = (e) => {
    e.preventDefault();
    send(value);
  };
  return <form onSubmit={onSubmitForm}>
    <input style={{ width: '100%' }} value={value} onChange={e => setValue(e.target.value)} />
  </form>;
};

const Chat = () => {
  const [author, setAuthor] = useState('');
  const [messages, setMessages] = useState<ChatMessage[]>([{ author: 'ycz', text: 'hello' }]);

  const socket = useWebsocket();
  useEffect(() => {
    socket.addEventListener("message", e => {
      const message: WSChatServerMessage = JSON.parse(e.data);
      // console.log("got message", message);[[[]]]
      switch (message.type) {
        case WSChatServerMessageType.MESSAGES:
          setMessages(message.chatMessages);
          break;
      }
    });
  }, [socket]);

  const sendMessage = useCallback((text) => {
    const message: WSChatClientMessage = {
      type: WSChatClientMessageType.NEW_MESSAGE,
      message: {
        text,
        author,
      }
    };
    socket.send(JSON.stringify(message));
  }, [author, socket]);

  return (
    <Background color="#333">
      <Container>
        <div>
          Your name: <input value={author} onChange={e => setAuthor(e.target.value)} />
        </div>
        <Messages messages={messages} />
        <TextBox send={sendMessage} />
      </Container>
    </Background>);
};

export default Chat;
