import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { SyncAlt } from "@styled-icons/fa-solid";
import {
  leaderboardSelector,
  useUpdateLeaderboard,
} from "./recoil/leaderboard";
import Loading from "src/components/Loading";

const LeaderboardContainer = styled.div`
  display: flex;
`;

const LeaderboardTable = styled.div`
  width: 300px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid gray;
`;

const LeaderboardRow = styled.div`
  width: calc(100% - 16px);
  padding: 4px 8px;

  :nth-child(2n) {
    background-color: tan;
  }
  :nth-child(2n + 1) {
    background-color: #e6dbcc;
  }
`;

const Cell = styled.div`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  :first-child {
    width: 45px;
    margin-right: 10px;
  }
  :nth-child(2) {
    width: 170px;
    margin-right: 10px;
  }
  :last-child {
    width: 45px;
    text-align: right;
  }
`;

const Refresh = styled(SyncAlt)`
  position: relative;
  left: 8px;
  top: 4px;
  cursor: pointer;
`;

const Leaderboard = () => {
  const scores = useRecoilValue(leaderboardSelector);
  const update = useUpdateLeaderboard();
  useEffect(() => {
    update();
  }, [update]);
  return (
    <LeaderboardContainer>
      <LeaderboardTable>
        <LeaderboardRow>
          <Cell>Rank</Cell>
          <Cell>Player</Cell>
          <Cell>Score</Cell>
        </LeaderboardRow>
        {scores.map(({ player, points, updatedAt }, index) => {
          const date = updatedAt ? new Date(updatedAt) : null;
          return (
            <LeaderboardRow key={player} title={date?.toLocaleString() ?? ""}>
              <Cell>{index + 1}</Cell>
              <Cell>{player}</Cell>
              <Cell>{points}</Cell>
            </LeaderboardRow>
          );
        })}
      </LeaderboardTable>
      <Refresh size={18} onClick={update} />
    </LeaderboardContainer>
  );
};

const LeaderboardSuspended = () => (
  <React.Suspense fallback={<Loading />}>
    <Leaderboard />
  </React.Suspense>
);

export default LeaderboardSuspended;
