import { css } from "styled-components";

export const BUTTON_HOVER_COLOR = "#666";
export const BUTTON_ACTIVE_COLOR = "#888";

export const hoverableButtonCss = css`
  :hover {
    background-color: ${BUTTON_HOVER_COLOR};
  }
  :active {
    background-color: ${BUTTON_ACTIVE_COLOR};
  }
`;
