import { RecoilRoot } from "recoil";
import styled from "styled-components";
import WordsquaredGrid from "./W2Grid";
import useW2Websocket from "./w2Websocket";
import W2Rack from "./W2Rack";
import RefreshButton from "./RefreshButton";
import UsernameForm from "./UsernameForm";
import ColorPickerButton from "./ColorPickerButton";
import { NAVBAR_HEIGHT } from "../components/NavBar";

const Background = styled.div`
  width: 100%;
  height: calc(100% - ${NAVBAR_HEIGHT}px - 4px);
  min-height: calc(100vh - ${NAVBAR_HEIGHT}px - 4px);
  background-color: #445;
  color: #bbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
`;

const UIRow = styled.div`
  display: flex;
  align-items: center;
`;

const ControlsText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Wordsquared = () => {
  useW2Websocket();
  return (
    <Background>
      <WordsquaredGrid />
      <UIRow>
        <W2Rack />
        <RefreshButton />
        <ColorPickerButton />
      </UIRow>
      <UsernameForm />
      <ControlsText>
        <div>Arrow keys or click to move cursor</div>
        <div>Shift to change typing direction, Enter to submit</div>
        <div>Space to recenter the camera, PageDn / PageUp or mouse wheel to zoom</div>
      </ControlsText>
    </Background>
  );
};

const WordsquaredContainer = () => {
  return <RecoilRoot>
    <Wordsquared />
  </RecoilRoot>;
};

export default WordsquaredContainer;