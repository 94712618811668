import { WSPlaygroundClientMessageType } from "../../../shared/websockets/playground";
import { WS_URL } from "../../../shared/constants";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { playerIdAtom } from "../../wordsquared/recoil/game";

export const sendGreeting = (socket: WebSocket, value: string) => {
  const message = {
    type: WSPlaygroundClientMessageType.GREETING,
    message: value,
  };
  socket.send(JSON.stringify(message));
};

const setupWebsocket = (id?: string | null) => {
  const url = id ? `${WS_URL}?${new URLSearchParams({ id })}` : WS_URL;
  let socket = new WebSocket(url);
  socket.addEventListener("open", () => {
    sendGreeting(socket, "Hello server!");
  });
  return socket;
};

export const useWebsocket = () => {
  const id = useRecoilValue(playerIdAtom);
  const [socket, setSocket] = useState<WebSocket>(setupWebsocket);
  useEffect(() => {
    socket.addEventListener("error", console.error);
    const onClose = () => {
      console.log("socket closed");
      // TODO: exponential backoff?
      setTimeout(() => {
        const newSocket = setupWebsocket(id);
        setSocket(newSocket);
      }, 1000);
    };
    socket.addEventListener("close", onClose);
    return () => {
      socket.removeEventListener("error", console.error);
      socket.removeEventListener("close", onClose);
    };
  }, [socket, id]);
  return socket;
};

export default setupWebsocket;
