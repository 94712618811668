import styled, { css } from "styled-components";
import {
  dropRight,
  chunk,
  find,
  last,
  sampleSize,
  sortBy,
  cloneDeep,
} from "lodash";
import { isEqual } from "lodash/fp";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Tile from "./Tile";
import { Refresh, Undo } from "@styled-icons/material";
import useOutsideCallback, {
  useOutsideCallbackDown,
} from "./useOutsideCallback";
import { isValidWord } from "shared/words";

export const LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export const LETTER_BAG =
  "AAAAAAAAAABBCCDDDDEEEEEEEEEEEEFFGGGHHIIIIIIIIIJKLLLLMMNNNNNNOOOOOOOOPPQRRRRRRSSSSTTTTTTUUUUVVWWXYYZ";
const CELL_SIZE = 70;
const MIN_WORD_LENGTH = 3;

const makeBoard = (
  letters: string | string[] | undefined = sampleSize(LETTER_BAG, 40),
  width = 8
) => {
  return chunk(letters, width);
};

interface GridProps {
  readonly columns: number;
  readonly rows: number;
}

type Coord = [number, number];

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, ${CELL_SIZE}px);
  grid-template-rows: repeat(${props => props.rows}, ${CELL_SIZE}px);
  /* border: 1px dotted gray;
  border-radius: 4px; */
  margin: 12px 0;
`;

const getIndicesInBox = ([row1, col1]: Coord, [row2, col2]: Coord) => {
  const [minRow, maxRow] = sortBy([row1, row2]);
  const [minCol, maxCol] = sortBy([col1, col2]);
  const indices: Coord[] = [];
  for (let row = minRow; row <= maxRow; row++) {
    for (let col = minCol; col <= maxCol; col++) {
      indices.push([row, col]);
    }
  }
  return indices;
};

type GameGrid = (string | null)[][];

const buttonCss = css`
  margin-top: 10px;
  cursor: pointer;
  :hover {
    background-color: rgba(1, 1, 1, 0.1);
  }
`;

const UndoButton = styled(Undo).attrs({ size: 24 })`
  ${buttonCss}
`;

const ResetButton = styled(Refresh).attrs({ size: 24 })`
  ${buttonCss}
`;

const WinScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WinScreen = ({ resetGrid, onUndo }) => (
  <WinScreenContainer>
    <h2>You won! 💪🥊</h2>
    <span>
      <UndoButton onClick={onUndo} />
      <ResetButton onClick={resetGrid} />
    </span>
  </WinScreenContainer>
);

interface GameProps {
  letters?: string;
  width?: number;
  onSolve?: () => void;
  winMessage?: string;
}

const isGridEmpty = grid => !grid.some(row => row.some(Boolean));

export const Game: React.FC<GameProps> = ({
  letters: propLetters,
  width: propWidth,
  onSolve,
}) => {
  const letters = propLetters || undefined;
  const width = propWidth || undefined;
  const [gridHistory, setGridHistory] = useState<GameGrid[]>([
    makeBoard(letters, width),
  ]);
  const grid = last(gridHistory) as GameGrid;
  const [boxStart, setBoxStart] = useState<Coord | null>(null);
  const [boxEnd, setBoxEnd] = useState<Coord | null>(null);
  const [tempBoxEnd, setTempBoxEnd] = useState<Coord | null>(null);
  const [submission, setSubmission] = useState("");
  const deselectAll = useCallback(() => {
    setBoxStart(null);
    setBoxEnd(null);
    setTempBoxEnd(null);
    setSubmission("");
  }, []);
  const initGrid = useCallback(() => {
    setGridHistory([makeBoard(letters, width)]);
    setSubmission("");
  }, [letters, width]);
  useEffect(() => {
    deselectAll();
    initGrid();
  }, [deselectAll, initGrid]);
  const resetGrid = useCallback(() => {
    deselectAll();
    setGridHistory([...gridHistory, gridHistory[0]]);
  }, [deselectAll, gridHistory]);
  const boxedIndices = useMemo(() => {
    if (!boxStart) return [];
    return getIndicesInBox(boxStart, (boxEnd || tempBoxEnd) as Coord);
  }, [boxStart, boxEnd, tempBoxEnd]);
  const boxedLetters: string[] = useMemo(
    () =>
      boxedIndices
        ? (boxedIndices
          .map(([row, col]) => grid[row][col])
          .filter(Boolean)
          .sort() as string[])
        : [],
    [boxedIndices, grid]
  );

  const gridRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const stopDragging = useCallback(() => {
    if (tempBoxEnd) {
      setBoxEnd(tempBoxEnd);
      setTempBoxEnd(null);
      setTimeout(() => inputRef?.current?.focus(), 0);
    }
  }, [tempBoxEnd]);
  useOutsideCallback(gridRef, stopDragging);
  useOutsideCallbackDown(containerRef, deselectAll);

  const inputRef = useRef<HTMLInputElement>(null);

  const onSubmit = useCallback(
    async event => {
      event.preventDefault();
      const letters = submission.toUpperCase().split("").sort();
      if (
        letters.length < MIN_WORD_LENGTH ||
        !isEqual(letters, boxedLetters) ||
        !isValidWord(submission)
      ) {
        setSubmission("");
        return;
      }
      const newGrid = cloneDeep(grid);
      boxedIndices?.forEach(([row, col]) => {
        newGrid[row][col] = null;
      });
      setGridHistory(history => [...history, newGrid]);
      setBoxStart(null);
      setBoxEnd(null);
      if (onSolve && isGridEmpty(newGrid)) {
        onSolve();
      }
      setSubmission("");
    },
    [submission, boxedLetters, boxedIndices, grid, onSolve]
  );
  const onUndo = useCallback(
    () => setGridHistory(history => dropRight(history)),
    []
  );

  const getTileOnMouseDown =
    (row: number, col: number) => (event: SyntheticEvent) => {
      event.preventDefault();
      setBoxEnd(null);
      setBoxStart([row, col]);
      setTempBoxEnd([row, col]);
    };
  const getTileOnMouseUp =
    (row: number, col: number) => (event: SyntheticEvent) => {
      event.preventDefault();
      if (!boxEnd) {
        setBoxEnd([row, col]);
        setTempBoxEnd(null);
        setTimeout(() => inputRef?.current?.focus(), 0);
      }
    };
  const getTileOnHover = (row: number, col: number) => () => {
    if (boxStart && !boxEnd) {
      setTempBoxEnd([row, col]);
    }
  };

  if (isGridEmpty(grid)) {
    return <WinScreen resetGrid={resetGrid} onUndo={onUndo} />;
  }
  return (
    <Container ref={containerRef}>
      <h1>Letter Boxing</h1>
      <div>
        Inspired by{" "}
        <a href="https://en.gamesaien.com/game/fruit_box/">Fruit Box</a>.
      </div>
      <audio controls loop>
        <source src="./fruitbox.mp3" type="audio/mpeg" />
      </audio>
      <Grid ref={gridRef} rows={grid.length} columns={grid[0].length}>
        {grid.map((row, r) =>
          row.map((value, c) => (
            <Tile
              key={`${r},${c}`}
              onMouseDown={getTileOnMouseDown(r, c)}
              onMouseUp={getTileOnMouseUp(r, c)}
              onHover={getTileOnHover(r, c)}
              highlighted={Boolean(find(boxedIndices, isEqual([r, c])))}
              value={value}
            />
          ))
        )}
      </Grid>
      <div>
        <div>Letters: {boxedLetters}</div>
        <form onSubmit={onSubmit}>
          Word:&nbsp;
          <input
            ref={inputRef}
            disabled={!boxEnd}
            value={submission}
            onChange={event => setSubmission(event.target.value)}
            onClick={event => event.stopPropagation()}
          />
        </form>
      </div>
      <span>
        {gridHistory.length > 1 && <UndoButton onClick={onUndo} />}
        {gridHistory.length > 1 && <ResetButton onClick={resetGrid} />}
      </span>
    </Container>
  );
};

export default Game;
