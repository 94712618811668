import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Background from "src/components/Background";
import Container from "src/components/Container";
import Loading from "src/components/Loading";
import { allPostsSelector } from "./recoil";
import { ReactTitle } from "react-meta-tags";

const PostList = () => {
  const posts = useRecoilValue(allPostsSelector);
  const links = posts
    .filter(post => post.listed)
    .map(post => (
      <div key={post.id}>
        <Link to={`/posts/${post.slug}`}>{post.title}</Link>
      </div>
    ));
  return (
    <>
      <ReactTitle title="Posts | Leon's Website" />
      <h1>Posts</h1>
      {links}
    </>
  );
};

const PostListContainer = () => (
  <Background color="slategray">
    <Container>
      <React.Suspense fallback={<Loading />}>
        <PostList />
      </React.Suspense>
    </Container>
  </Background>
);

export default PostListContainer;
