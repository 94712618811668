import Background from "src/components/Background";
import Container from "src/components/Container";
import { ReactTitle } from "react-meta-tags";
import Anagrams from "./Anagrams";

const AnagramGame = () => {
  return (
    <Background color="#bcd">
      <ReactTitle title="Anagram Trainer | Leon's Website" />
      <Container>
        <Anagrams />
      </Container>
    </Background>
  );
};

export default AnagramGame;
