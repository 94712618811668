import { LETTERS } from "shared/words";
import { Coordinates, WorderyBoard } from "shared/wordery/types";
import { pullAt, set } from "lodash/fp";
import { useCallback, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  CursorDirection,
  cursorDirectionAtom,
  selectedCellAtom,
} from "./controls";
import { boardAtom, rackAtom } from "./gameState";

const nextEmptyInDirection = (
  direction: Coordinates,
  selectedCell: Coordinates,
  board: WorderyBoard
): Coordinates | null => {
  const [dx, dy] = direction;
  let [currX, currY] = selectedCell;
  do {
    currX += dx;
    currY += dy;
  } while (board?.[currX]?.[currY] && board?.[currX]?.[currY]?.contents);
  if (board?.[currX]?.[currY]) {
    return [currX, currY];
  }
  return null;
};

const useOnLetterInput = () => {
  const [selectedCell, setSelectedCell] = useRecoilState(selectedCellAtom);
  const cursorDirection = useRecoilValue(cursorDirectionAtom);
  const [rack, setRack] = useRecoilState(rackAtom);
  const [board, setBoard] = useRecoilState(boardAtom);

  return useCallback(
    (letter: string) => {
      if (!selectedCell) {
        return;
      }
      const tileIndex = rack.findIndex(tile => tile.letter === letter);
      if (tileIndex === -1) {
        return;
      }
      let [currX, currY] = selectedCell;
      const direction: Coordinates =
        cursorDirection === CursorDirection.RIGHT ? [0, 1] : [1, 0];
      setRack(pullAt([tileIndex]));
      setBoard(set([currX, currY, "contents"], rack[tileIndex]));
      const nextSelection = nextEmptyInDirection(
        direction,
        selectedCell,
        board
      );
      setSelectedCell(nextSelection);
    },
    [
      selectedCell,
      setSelectedCell,
      cursorDirection,
      rack,
      setRack,
      board,
      setBoard,
    ]
  );
};

export const useKeyboardCallback = () => {
  const [selectedCell, setSelectedCell] = useRecoilState(selectedCellAtom);
  const setCursorDirection = useSetRecoilState(cursorDirectionAtom);
  const onLetterInput = useOnLetterInput();

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!selectedCell) return;

      if (event.key === "ArrowDown") {
        setCursorDirection(CursorDirection.DOWN);
        event.preventDefault();
      }
      if (event.key === "ArrowRight") {
        setCursorDirection(CursorDirection.RIGHT);
        event.preventDefault();
      }
      if (LETTERS.includes(event.key.toUpperCase())) {
        onLetterInput(event.key.toUpperCase());
      }
    },
    [setCursorDirection, selectedCell, onLetterInput]
  );
  // const onKeyUp = useCallback(
  //   (event: KeyboardEvent) => {
  //     if (!selectedCell) return;
  //   },
  //   [selectedCell, cursorDirection, setSelectedCell, setCursorDirection]
  // );

  useEffect(() => {
    // document.addEventListener("keyup", onKeyUp, true);
    document.addEventListener("keydown", onKeyDown, true);
    return () => {
      // document.removeEventListener("keyup", onKeyUp, true);
      document.removeEventListener("keydown", onKeyDown, true);
    };
  }, [onKeyDown]);
};
