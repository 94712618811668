import { Coords } from "../../shared/wordsquared/types";

export const gridToCanvasTranslator =
  (width: number, height: number, [cx, cy]: Coords) =>
  (gridX: number, gridY: number): Coords => {
    const xDelta = gridX - cx;
    const yDelta = gridY - cy;
    return [width / 2 + xDelta, height / 2 - yDelta];
  };

export type GridToCanvasTranslator = ReturnType<typeof gridToCanvasTranslator>;

export const canvasToGridTranslator =
  (width: number, height: number, [cx, cy]: Coords) =>
  (canvasX: number, canvasY: number): Coords => {
    return [canvasX - width / 2 + cx, -(canvasY - height / 2) + cy];
  };

// Returns camera coordinates for the center of the specified tile
export const tileToGridCoords = (
  [x, y]: Coords,
  squareSize: number
): Coords => {
  return [squareSize * (x + 0.5), squareSize * (y + 0.5)];
};

export const gridToTileCoords = (
  [x, y]: Coords,
  squareSize: number
): Coords => {
  return [x / squareSize, y / squareSize].map(Math.floor) as Coords;
};

// canvasX = width / 2 + (gridx - cx);
// gridx = canvasX - width / 2 + cx;

// canvasY = height / 2 - (gridY - cy);
// gridY = -(canvasY - height / 2) + cy;
