import { isNil, prop, reject, sum, unzip } from "lodash/fp";
import { isValidWord } from "../words";
import { Bonus, Tile, Word, WorderyBoard } from "./types";

export const getRowWords = function* (board: WorderyBoard) {
  for (const row of board) {
    let currWord: Word = { tiles: [], score: 0 };
    let multiplier = 1;
    const paddedRow = [...row, { contents: null, bonus: null }];
    for (const { contents, bonus } of paddedRow) {
      if (contents === null) {
        if (currWord.tiles.length > 1) {
          currWord.score *= multiplier;
          yield currWord;
        }
        multiplier = 1;
        currWord = { tiles: [], score: 0 };
      } else {
        currWord.tiles.push(contents);
        switch (bonus) {
          case Bonus.DoubleLetter:
            currWord.score += contents.score * 2;
            break;
          case Bonus.DoubleWord:
            multiplier *= 2;
          // falls through
          default:
            currWord.score += contents.score;
        }
      }
    }
  }
  return;
};

export const getWords = (board: WorderyBoard) => {
  return [...getRowWords(board), ...getRowWords(unzip(board))];
};

export const getInvalidWords = (maybeWords: Word[]) => {
  const strings = maybeWords.map(word =>
    word.tiles.map(tile => tile.letter).join("")
  );
  const invalidWords = reject(isValidWord, strings);
  return invalidWords;
};

export const getScoreFromWords = (words: Word[]) =>
  sum(words.map(prop("score")));

export const getScoreFromBoard = (board: WorderyBoard) =>
  getScoreFromWords(getWords(board));

export const getTilesFromBoard = (board: WorderyBoard) => {
  const allContents = board.flat().map(cell => cell.contents);
  return reject(isNil, allContents) as Tile[];
};

export const getEmptyBoard = (board: WorderyBoard) => {
  return board.map(row =>
    row.map(cell => ({
      ...cell,
      contents: null,
    }))
  );
};
