import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { Sparse2DArray } from "../../../shared/wordsquared/gridUtils";
import { BoardTile, Coords, CursorDirection, Letter, PlayTile } from "../../../shared/wordsquared/types";
import { GridToCanvasTranslator, gridToCanvasTranslator } from "../gridCanvasMath";
import { cursorLocationAtomW2, cursorDirectionAtomW2, pendingMoveAtom, gridTilesS2ASelector, pendingColorAtom } from "../recoil/game";
import { cameraLocationAtom, gridSquareSizeSelector } from '../recoil/camera';
import withSavedContext from "../../utils/withSavedContext";
import getTextColorForBackground from "../../utils/getTextColorForBackground";

const BACKGROUND_COLOR = '#334';

const GRID_LINE_WIDTH_RATIO = 16;
const GRID_LINE_COLOR = '#556';

export const TILE_TEXT_COLOR = '#ddd';
export const DEFAULT_TILE_COLOR = '#622020';
export const TILE_STROKE_COLOR = 'rgba(1,1,1,0.3)';
const TILE_LINE_WIDTH = 2;
const TILE_SIZE_RATIO = 0.8125;
const TILE_FONT_RATIO = 0.75;
const TEXT_SHIFT_RATIO = TILE_FONT_RATIO / 12;

const CURSOR_STROKE_COLOR = '#eee';
const CURSOR_LINE_WIDTH_RATIO = 16;

const DRAW_LETTERS_THRESHOLD = 5;
const DRAW_GRID_THRESHOLD = 5;

const drawGrid = withSavedContext((ctx: CanvasRenderingContext2D, gridSquareSize: number, [cx, cy]: Coords) => {
  const { width, height } = ctx.canvas;
  const translate = gridToCanvasTranslator(width, height, [cx, cy]);

  ctx.strokeStyle = GRID_LINE_COLOR;
  ctx.lineWidth = gridSquareSize / GRID_LINE_WIDTH_RATIO;

  const widthSquares = Math.ceil(width / gridSquareSize);
  const heightSquares = Math.ceil(height / gridSquareSize);
  const xSquares = Math.floor(cx / gridSquareSize);
  const ySquares = Math.floor(cy / gridSquareSize);
  const xStart = (xSquares - Math.floor(widthSquares / 2)) * gridSquareSize;
  const yStart = (ySquares - Math.floor(heightSquares / 2)) * gridSquareSize;

  for (let x = xStart; x < xStart + width + 100; x += gridSquareSize) {
    const [lineX,] = translate(x, 0);
    ctx.beginPath();
    ctx.moveTo(lineX, 0);
    ctx.lineTo(lineX, height);
    ctx.stroke();
  }

  for (let y = yStart; y < yStart + height + 100; y += gridSquareSize) {
    const [, lineY] = translate(0, y);
    ctx.beginPath();
    ctx.moveTo(0, lineY);
    ctx.lineTo(width, lineY);
    ctx.stroke();
  }
});

type CanvasTile = PlayTile & Partial<BoardTile>;

const drawTile = withSavedContext((
  ctx: CanvasRenderingContext2D,
  gridSquareSize: number,
  translate: GridToCanvasTranslator,
  { x, y, letter }: CanvasTile,
  fillColor: string = DEFAULT_TILE_COLOR,
  highlight: boolean = false,
) => {
  const [cx, cy] = translate(
    x * gridSquareSize + gridSquareSize / 2,
    y * gridSquareSize + gridSquareSize / 2
  );
  const TILE_SIZE = gridSquareSize * TILE_SIZE_RATIO;
  const TEXT_SHIFT_Y = gridSquareSize * TEXT_SHIFT_RATIO;
  // Tile
  ctx.fillStyle = fillColor;
  ctx.lineWidth = TILE_LINE_WIDTH;
  ctx.beginPath();
  ctx.rect(cx - TILE_SIZE / 2, cy - TILE_SIZE / 2, TILE_SIZE, TILE_SIZE);
  ctx.fill();
  if (highlight) {
    const [cornerX, cornerY] = translate(x * gridSquareSize, (y + 1) * gridSquareSize);
    ctx.strokeStyle = CURSOR_STROKE_COLOR;
    ctx.lineWidth = gridSquareSize / CURSOR_LINE_WIDTH_RATIO;
    ctx.strokeRect(cornerX, cornerY, gridSquareSize, gridSquareSize);
  }
  if (gridSquareSize > DRAW_GRID_THRESHOLD) {
    ctx.strokeStyle = TILE_STROKE_COLOR;
    ctx.stroke();
  }
  if (gridSquareSize > DRAW_LETTERS_THRESHOLD) {
    // Text
    ctx.fillStyle = getTextColorForBackground(fillColor);
    ctx.fillText(letter, cx, cy + TEXT_SHIFT_Y);
  }
});

const drawTiles = (ctx: CanvasRenderingContext2D, gridSquareSize: number, center: Coords, tiles: Sparse2DArray<BoardTile>) => {
  const translate = gridToCanvasTranslator(ctx.canvas.width, ctx.canvas.height, center);
  tiles.forEach(({ letter, color }, [x, y]) => drawTile(ctx, gridSquareSize, translate, { letter, x, y }, color));
};

const drawCursor = withSavedContext((ctx: CanvasRenderingContext2D, gridSquareSize: number, translate: GridToCanvasTranslator, cursorLocation: Coords, direction: CursorDirection) => {
  const [x, y] = cursorLocation;
  const [cornerX, cornerY] = translate(x * gridSquareSize, (y + 1) * gridSquareSize);
  ctx.strokeStyle = CURSOR_STROKE_COLOR;
  ctx.lineWidth = gridSquareSize / CURSOR_LINE_WIDTH_RATIO;
  ctx.strokeRect(cornerX, cornerY, gridSquareSize, gridSquareSize);
  ctx.beginPath();
  if (direction === CursorDirection.RIGHT) {
    ctx.moveTo(cornerX + 0.25 * gridSquareSize, cornerY + 0.5 * gridSquareSize);
    ctx.lineTo(cornerX + 0.75 * gridSquareSize, cornerY + 0.5 * gridSquareSize);
    ctx.stroke();
  } else {
    ctx.moveTo(cornerX + 0.5 * gridSquareSize, cornerY + 0.25 * gridSquareSize);
    ctx.lineTo(cornerX + 0.5 * gridSquareSize, cornerY + 0.75 * gridSquareSize);
    ctx.stroke();

  }
});

const drawPendingMove = (ctx: CanvasRenderingContext2D, gridSquareSize: number, translate: GridToCanvasTranslator, pendingMove: Sparse2DArray<Letter>, color: string) => {
  pendingMove.forEach((letter, [x, y]) => {
    drawTile(ctx, gridSquareSize, translate, { x, y, letter }, color, true);
  });
};

const useDraw = () => {
  const gridSquareSize = useRecoilValue(gridSquareSizeSelector);
  const cameraLocation = useRecoilValue(cameraLocationAtom);
  const gridTiles = useRecoilValue(gridTilesS2ASelector);
  const cursorLocation = useRecoilValue(cursorLocationAtomW2);
  const cursorDirection = useRecoilValue(cursorDirectionAtomW2);
  const pendingMove = useRecoilValue(pendingMoveAtom);
  const pendingColor = useRecoilValue(pendingColorAtom);
  const draw = useCallback((ctx: CanvasRenderingContext2D) => {
    const { width, height } = ctx.canvas;
    const translate = gridToCanvasTranslator(ctx.canvas.width, ctx.canvas.height, cameraLocation);
    ctx.clearRect(0, 0, width, height);
    ctx.font = `${gridSquareSize * TILE_FONT_RATIO}px Consolas,sans-serif`;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    ctx.fillStyle = BACKGROUND_COLOR;
    ctx.fillRect(0, 0, width, height);
    if (gridSquareSize > DRAW_GRID_THRESHOLD) {
      drawGrid(ctx, gridSquareSize, cameraLocation);
    }
    // TODO: Draw only tiles on screen or nearby
    drawTiles(ctx, gridSquareSize, cameraLocation, gridTiles);
    drawCursor(ctx, gridSquareSize, translate, cursorLocation, cursorDirection);
    drawPendingMove(ctx, gridSquareSize, translate, pendingMove, pendingColor);
  }, [gridSquareSize, cameraLocation, gridTiles, cursorLocation, cursorDirection, pendingMove, pendingColor]);
  return draw;
};

export default useDraw;