// TODO change to use this
export type Letter =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "I"
  | "J"
  | "K"
  | "L"
  | "M"
  | "N"
  | "O"
  | "P"
  | "Q"
  | "R"
  | "S"
  | "T"
  | "U"
  | "V"
  | "W"
  | "X"
  | "Y"
  | "Z";

export type WorderyGameParams = {
  board: WorderyBoard;
  rack: Tile[];
};

export type WorderyBoard = BoardCell[][];

export enum Bonus {
  DoubleLetter = "double_letter",
  DoubleWord = "double_word",
}

export interface BoardCell {
  contents: Tile | null;
  bonus: Bonus | null;
}

export type Coordinates = [number, number];

export type Rack = Tile[];

export interface Tile {
  letter: string;
  score: number;
}

export type Word = {
  tiles: Tile[];
  score: number;
};

export enum TileLocation {
  Board = "board",
  Rack = "rack",
}

export interface DragSource {
  location: TileLocation;
  position: any; // refactor Rack to be a grid
}

export const DRAG_TYPES = {
  BOARD_TILE: "board_tile",
};

export interface DropItem {
  type: "board_tile";
  tile: Tile;
  source: DragSource;
}

export interface LeaderboardEntry {
  player: string;
  points: number;
  updatedAt: Date;
}

export interface WorderyDaily {
  date: string;
}
