import { Palette } from "@styled-icons/fa-solid";
import { useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { pendingColorAtom } from "./recoil/game";
import { ColorResult, SketchPicker } from 'react-color';
import styled from "styled-components";
import { hoverableButtonCss } from "./buttonHover";
import useOutsideCallback from "../pages/boxing/useOutsideCallback";
import { useFocusCanvas } from "./hooks/useFocus";

const PickerContainer = styled.div`
  padding: 8px;
  ${hoverableButtonCss}
`;

const MyPicker = styled(SketchPicker)`
  position: absolute;
  bottom: 0;
  left: 40px;
`;

const ColorPickerButton = () => {
  const pickerRef = useRef<HTMLDivElement>(null);
  const [color, setColor] = useRecoilState(pendingColorAtom);
  const [isVisible, setIsVisible] = useState(false);
  useOutsideCallback(pickerRef, () => pickerRef.current && setIsVisible(false));
  const focusCanvas = useFocusCanvas();
  const onChange = (color: ColorResult) => {
    setColor(color.hex);
    focusCanvas();
  };
  return <PickerContainer style={{ position: 'relative' }}>
    <Palette height="22" onClick={() => setIsVisible(visible => !visible)} />
    <div ref={pickerRef}>
      {isVisible && <MyPicker color={color} disableAlpha onChangeComplete={onChange} />}
    </div>
  </PickerContainer>;
};

export default ColorPickerButton;

// const [color, setColor] = useState('#ffffff');
// const handleChange = color => setColor(color);
/* AlphaPicker<AlphaPicker color={color} onChangeComplete={handleChange} /> <hr />
  BlockPicker<BlockPicker color={color} onChangeComplete={handleChange} /> <hr />
  ChromePicker<ChromePicker color={color} onChangeComplete={handleChange} /> <hr />
  CirclePicker<CirclePicker color={color} onChangeComplete={handleChange} /> <hr />
  CompactPicker<CompactPicker color={color} onChangeComplete={handleChange} /> <hr />
  GithubPicker<GithubPicker color={color} onChangeComplete={handleChange} /> <hr />
  HuePicker<HuePicker color={color} onChangeComplete={handleChange} /> <hr />
  MaterialPicker<MaterialPicker color={color} onChangeComplete={handleChange} /> <hr />
  PhotoshopPicker<PhotoshopPicker color={color} onChangeComplete={handleChange} /> <hr />
  SketchPicker<SketchPicker color={color} onChangeComplete={handleChange} /> <hr />
  SliderPicker<SliderPicker color={color} onChangeComplete={handleChange} /> <hr />
  SwatchesPicker<SwatchesPicker color={color} onChangeComplete={handleChange} /> <hr />
  TwitterPicker<TwitterPicker color={color} onChangeComplete={handleChange} /> <hr /> */
