const withSavedContext = <Args extends Array<any>, ReturnType>(
  fn: (ctx: CanvasRenderingContext2D, ...rest: Args) => ReturnType
) => {
  return (ctx: CanvasRenderingContext2D, ...rest: Args) => {
    ctx.save();
    const result = fn(ctx, ...rest);
    ctx.restore();
    return result;
  };
};

export default withSavedContext;
