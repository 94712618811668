import * as React from "react";
import styled from "styled-components";

import TileComponent, { TILE_SIZE } from "./Tile";
import { useDrop } from "react-dnd";
import { DRAG_TYPES, DropItem, TileLocation } from "shared/wordery/types";
import { useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  initialRackAtom,
  rackAtom,
  useClearBoard,
  useDropTileOnRack,
} from "./recoil";
import { useCallback } from "react";
import { shuffle } from "lodash/fp";
import { sortTilesByLetter, sortTilesByValue } from "shared/wordery/sorting";
import ActionButton from "src/components/ActionButton";

interface RackBoxProps {
  isHovering: boolean;
  rackSize: number;
}

const RACK_TILE_WIDTH = 4;

const RackBox = styled.div<RackBoxProps>`
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  background-color: ${props => (props.isHovering ? "yellow" : "none")};
  border: 1px solid black;
  border-radius: 2px;
  width: ${(TILE_SIZE + 6) * RACK_TILE_WIDTH}px;
  height: ${props =>
    (TILE_SIZE + 6) * Math.ceil(props.rackSize / RACK_TILE_WIDTH)}px;
  padding: 3px;
`;

const RackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RackTile = styled(TileComponent)`
  margin: 2px;
`;

const useOnClickSetRack = (predicate: any) => {
  const setRack = useSetRecoilState(rackAtom);
  return useCallback(() => setRack(predicate), [predicate, setRack]);
};

const useOnClickSortAlpha = () => useOnClickSetRack(sortTilesByLetter);
const useOnClickSortValue = () => useOnClickSetRack(sortTilesByValue);
const useOnClickShuffle = () => useOnClickSetRack(shuffle);

const Buttons = () => {
  const onClickSortAlpha = useOnClickSortAlpha();
  const onClickSortValue = useOnClickSortValue();
  const onClickShuffle = useOnClickShuffle();
  const onClickClearBoard = useClearBoard();
  return (
    <>
      <div>
        <ActionButton onClick={onClickSortAlpha} value="Sort by letter" />
        <ActionButton onClick={onClickSortValue} value="Sort by value" />
        <ActionButton onClick={onClickShuffle} value="Shuffle" />
      </div>
      <div>
        <ActionButton onClick={onClickClearBoard} value="Clear board" />
      </div>
    </>
  );
};

const RackComponent = () => {
  const rack = useRecoilValue(rackAtom);
  const initialRack = useRecoilValue(initialRackAtom);
  const onDropTile = useDropTileOnRack();
  const [{ isHovering }, drop] = useDrop({
    accept: DRAG_TYPES.BOARD_TILE,
    drop: (item: DropItem) => {
      const { tile, source } = item;
      onDropTile(tile, source);
    },
    collect: monitor => ({
      item: monitor.getItem(),
      isHovering: monitor.isOver(),
    }),
  });
  const sources = useMemo(
    () =>
      rack.map((_, index) => ({
        location: TileLocation.Rack,
        position: index,
      })),
    [rack]
  );
  return (
    <RackContainer>
      <RackBox ref={drop} isHovering={isHovering} rackSize={initialRack.length}>
        {rack.map(
          (tile, index) =>
            tile && <RackTile key={index} tile={tile} source={sources[index]} />
        )}
      </RackBox>
      <Buttons />
    </RackContainer>
  );
};

export default React.memo(RackComponent);
