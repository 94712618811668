import { MouseEvent, useCallback, useContext, WheelEvent } from "react";
import { atom, DefaultValue, selector, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Coords } from "../../../shared/wordsquared/types";
import { getCurrentDimensions, BASE_GRID_SQUARE_SIZE_PX } from "../constants";
import { canvasToGridTranslator } from "../gridCanvasMath";
import { CanvasRefContext } from "../hooks/useFocus";

export const canvasDimensionsAtom = atom<[number, number]>({
  key: 'wordsquared/canvas-dims',
  default: getCurrentDimensions()
});

// Higher = more zoomed in
export const cameraZoomAtom = atom<number>({
  key: 'wordsquared/camera-zoom',
  default: 1
});

export const gridSquareSizeSelector = selector({
  key: 'wordsquared/grid-square-size',
  get: ({ get }) => {
    return get(cameraZoomAtom) * BASE_GRID_SQUARE_SIZE_PX;
  }
});

export const currentDimensionsInSquaresSelector = selector<[number, number]>({
  key: 'wordsquared/current-dimensions-in-squares',
  get: ({ get }) => {
    const [width, height] = get(canvasDimensionsAtom);
    const gridSquareSize = get(gridSquareSizeSelector);
    return [width / gridSquareSize, height / gridSquareSize];
  }
});

export const cameraLocationAtom = atom<Coords>({
  key: 'wordsquared/camera-location',
  default: [BASE_GRID_SQUARE_SIZE_PX / 2, BASE_GRID_SQUARE_SIZE_PX / 2]
});

export const cameraLocationSquaresSelector = selector<Coords>({
  key: 'wordsquared/camera-location-squares',
  get: ({ get }) => {
    const [cx, cy] = get(cameraLocationAtom);
    const gridSquareSize = get(gridSquareSizeSelector);
    return [Math.floor(cx / gridSquareSize), Math.floor(cy / gridSquareSize)];
  },
  set: ({ get, set, reset }, value) => {
    if (value instanceof DefaultValue) {
      reset(cameraLocationAtom);
      return;
    }
    const [xSquares, ySquares] = value;
    const gridSquareSize = get(gridSquareSizeSelector);
    set(cameraLocationAtom, [
      (xSquares + 0.5) * gridSquareSize,
      (ySquares + 0.5) * gridSquareSize
    ] as Coords);
  }
});

export const useClientToGridCoords = () => {
  const canvasRef = useContext(CanvasRefContext);
  const cameraLocation = useRecoilValue(cameraLocationAtom);

  return useCallback(
    ({ clientX, clientY }: MouseEvent): Coords => {
      console.log("🚀 / useClientToGridCoords / clientX, clientY", clientX, clientY);
      if (!canvasRef.current) {
        return [0, 0];
      }
      const { width, height, } = canvasRef.current;
      const { x: cornerX, y: cornerY } = canvasRef.current.getBoundingClientRect();
      const clickX = clientX - cornerX;
      const clickY = clientY - cornerY;
      return canvasToGridTranslator(width, height, cameraLocation)(clickX, clickY);
    }, [canvasRef, cameraLocation]);
};

const zoomHook = (zoomFn: (zoom: number) => number) => {
  return () => {
    const [cameraLocationSquares, setCameraLocationSquares] = useRecoilState(cameraLocationSquaresSelector);
    const setCameraZoom = useSetRecoilState(cameraZoomAtom);
    return useCallback(() => {
      setCameraZoom(zoomFn);
      setCameraLocationSquares(cameraLocationSquares);
    }, [setCameraZoom, setCameraLocationSquares, cameraLocationSquares]);
  };
};

export const useZoomOut = zoomHook(x => Math.min(x * 0.75, 3));
export const useZoomIn = zoomHook(x => Math.min(x / 0.75, 3));

export const useOnScrollCanvas = () => {
  const zoomOut = useZoomOut();
  const zoomIn = useZoomIn();
  return useCallback(({ deltaY }: WheelEvent) => {
    if (deltaY < 0) {
      zoomIn();
    } else if (deltaY > 0) {
      zoomOut();
    }
  }, [zoomIn, zoomOut]);
};