import { useCallback, useEffect } from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import {
  W2InvalidPlayType,
  W2ServerMessage,
  W2ServerMessageType,
} from "../../shared/wordsquared/w2Messages";
import { useWebsocket } from "../utils/websocket/setup";
import { NotifType } from "./notifs";
import {
  gridAreasAtom,
  notifsAtom,
  baseRackAtom,
  currentRackAtom,
  pendingMoveAtom,
  playerIdAtom,
} from "./recoil/game";
import { useFocusUsername } from "./hooks/useFocus";

export let w2Socket: WebSocket;

const useHandleIncomingMessages = (socket: WebSocket) => {
  const setPlayerId = useSetRecoilState(playerIdAtom);
  const setGridAreas = useSetRecoilState(gridAreasAtom);
  const setNotifs = useSetRecoilState(notifsAtom);
  const setBaseRack = useSetRecoilState(baseRackAtom);
  const setCurrentRack = useSetRecoilState(currentRackAtom);
  const resetPendingMove = useResetRecoilState(pendingMoveAtom);
  const focusUsername = useFocusUsername();
  const messageHandler = useCallback(
    e => {
      const message: W2ServerMessage = JSON.parse(e.data);
      switch (message.type) {
        case W2ServerMessageType.WELCOME:
          const { playerId } = message;
          setPlayerId(playerId);
          break;
        case W2ServerMessageType.BOARD_UPDATE:
          const { gridAreas } = message;
          setGridAreas(gridAreas);
          break;
        case W2ServerMessageType.INVALID_PLAY:
          const { reason } = message;
          setNotifs(notifs => [
            ...notifs,
            {
              type: NotifType.INVALID_PLAY,
              reason,
            },
          ]);
          if (reason === W2InvalidPlayType.ILLEGAL_USERNAME) {
            focusUsername();
          }
          break;
        case W2ServerMessageType.RACK_UPDATE:
          const { rack } = message;
          resetPendingMove();
          setBaseRack(rack);
          setCurrentRack(rack);
          break;
      }
    },
    [
      setPlayerId,
      setGridAreas,
      setNotifs,
      focusUsername,
      setBaseRack,
      setCurrentRack,
      resetPendingMove,
    ]
  );
  useEffect(() => {
    socket.addEventListener("message", messageHandler);
    return () => {
      socket.removeEventListener("message", messageHandler);
    };
  }, [messageHandler, socket]);
};

const useW2Websocket = () => {
  w2Socket = useWebsocket();
  useHandleIncomingMessages(w2Socket);
  return w2Socket;
};

export default useW2Websocket;
