import { selector } from "recoil";
import { Post } from "shared/posts";
import { fetchFromApi } from "src/utils/api";
import { cacheIdAtom } from "src/utils/recoilCache";

export const allPostsSelector = selector<Post[]>({
  key: "posts/all-posts",
  get: async ({ get }) => {
    get(cacheIdAtom);
    const res = await fetchFromApi("/posts");
    const posts: Post[] = await res.json();
    return posts;
  },
});
