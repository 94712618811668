// Client-to-server messages
export enum WSPlaygroundClientMessageType {
  GREETING = "greeting",
  SAVE = "save",
  GET = "get",
}

type WSGreetingClientMessage = {
  type: WSPlaygroundClientMessageType.GREETING;
  message: string;
};

type WSSaveClientMessage = {
  type: WSPlaygroundClientMessageType.SAVE;
  value: string;
};

type WSGetClientMessage = {
  type: WSPlaygroundClientMessageType.GET;
};

export type WSPlaygroundClientMessage =
  | WSGreetingClientMessage
  | WSSaveClientMessage
  | WSGetClientMessage;

// Server-to-client messages
export enum WSPlaygroundServerMessageType {
  GREETING = "greeting",
  GET_RESPONSE = "get_response",
}

type WSGreetingServerMessage = {
  type: WSPlaygroundServerMessageType.GREETING;
  message: string;
};

type WSGetResponseServerMessage = {
  type: WSPlaygroundServerMessageType.GET_RESPONSE;
  value: string;
};

export type WSPlaygroundServerMessage =
  | WSGreetingServerMessage
  | WSGetResponseServerMessage;
