import * as React from "react";
import styled from "styled-components";
import { useDrag } from "react-dnd";

import { DragSource, DRAG_TYPES, Tile } from "shared/wordery/types";

interface TileContainerProps {
  canDrag: boolean;
  isDragging: boolean;
}

export const TILE_SIZE = 60;

const TileContainer = styled.div<TileContainerProps>`
  display: flex;
  position: relative;
  font-size: ${(TILE_SIZE * 36) / 50}px;
  font-family: "Consolas", sans-serif;
  justify-content: center;
  align-items: center;
  height: ${TILE_SIZE}px;
  width: ${TILE_SIZE}px;
  background-color: Beige;
  border: 1px solid black;
  cursor: ${props => (props.canDrag ? "grab" : "default")};
  opacity: ${props => (props.isDragging ? 0.5 : 1)};
`;

const ScoreContainer = styled.div`
  position: absolute;
  right: 2px;
  bottom: 0px;
  font-size: ${(TILE_SIZE * 14) / 50}px;
`;

interface TileProps extends React.HTMLAttributes<HTMLDivElement> {
  tile: Tile;
  source: DragSource;
  isDraggingPreview?: boolean;
}

const TileComponent: React.FC<TileProps> = ({
  className,
  tile,
  source,
  isDraggingPreview = false,
}) => {
  const [{ isDragging }, drag]: any = useDrag({
    item: {
      type: DRAG_TYPES.BOARD_TILE,
      tile,
      source,
    },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
      // canDrag: monitor.canDrag()
    }),
  });
  return (
    <TileContainer
      canDrag
      className={className}
      ref={drag}
      isDragging={isDraggingPreview || isDragging}
    >
      {tile.letter}
      <ScoreContainer>{tile.score}</ScoreContainer>
    </TileContainer>
  );
};

export default TileComponent;
