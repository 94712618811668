import styled from "styled-components";
import { NAVBAR_HEIGHT } from "./NavBar";

const Background = styled.div<{ color?: string }>`
  width: 100%;
  height: calc(100% - ${NAVBAR_HEIGHT}px - 4px);
  min-height: calc(100vh - ${NAVBAR_HEIGHT}px - 4px);
  background-color: ${props => props.color || "#cec"};

  display: flex;
  flex-direction: column;
`;

export default Background;
