import Background from "src/components/Background";
import Container from "src/components/Container";
import Game from "./Game";
import { ReactTitle } from "react-meta-tags";

const Boxing = () => {
  return (
    <Background color="#dcb">
      <ReactTitle title="Letter Boxing | Leon's Website" />
      <Container>
        <Game />
      </Container>
    </Background>
  );
};

export default Boxing;
