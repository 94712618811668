import isValidColorString from "./isValidColorString";
import { RGBArray } from "./parseRGBArray";

const parseColorString = (color: string): RGBArray => {
  if (!isValidColorString(color)) {
    throw new Error("Invalid color string provided");
  }

  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);
  return [r, g, b];
};

export default parseColorString;
