import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { pendingColorAtom, selectedTileSelector } from "../recoil/game";
import { DEFAULT_TILE_COLOR } from "./useDraw";

const useCopyColorAtCursor = () => {
  const selectedTile = useRecoilValue(selectedTileSelector);
  const setPendingColor = useSetRecoilState(pendingColorAtom);
  return useCallback(() => {
    setPendingColor(selectedTile?.color ?? DEFAULT_TILE_COLOR);
  }, [setPendingColor, selectedTile]);
};

export default useCopyColorAtCursor;
