import { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import styled from "styled-components";

import Board from "./Board";
import CustomDragPreview from "./CustomDragPreview";
import Rack from "./Rack";

const GameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  > div {
    margin: 10px;
  }
`;

const Game = () => {
  return (
    <DndProvider options={HTML5toTouch}>
      <GameContainer>
        <Board />
        <Rack />
      </GameContainer>
      <CustomDragPreview />
    </DndProvider>
  );
};

const StyledGame = styled(Game)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default StyledGame;
