import _ from "lodash";
import normalize from "src/utils/normalize";
import { Player, PlayerRank, RanksJson } from "./types";

const mainOverrides = {
  Plup: ["Fox"],
  Mew2King: ["Fox"],
  Darkatma: ["Peach"],
  Santi: ["Sheik"],
  Medz: ["Marth"],
  Sung: ["Sheik"],
  Swiftbass: ["Fox"],
  "Lord HDL": ["Link"],
  Azel: ["Fox"],
  Kurv: ["Fox"],
};

const processCharacters = (ranks: PlayerRank[]) => {
  const characterLists = _.map(ranks, "characters");
  const characters = _.uniq(characterLists.flat());
  let mainsSet = new Set<string>();
  characterLists.forEach(chars => {
    mainsSet.add(chars[0]);
  });
  const overrides = mainOverrides[ranks[0].tag] ?? [];
  overrides.forEach(main => {
    mainsSet.add(main);
  });
  const mains = [...mainsSet];
  return { mains, secondaries: _.difference(characters, mains) };
};

const processPlayers = (rankings: RanksJson): { [tag: string]: Player } => {
  const allRanks = rankings.map(ranking => ranking.ranks).flat();
  const playerCatalog = _.groupBy(allRanks, "tag");
  const players = _.mapValues(playerCatalog, (ranks, tag) => {
    const countries = _.uniq(_.map(ranks, "country"));
    const sortedRanks = _.sortBy(ranks, rank => String(rank.ranking));
    const { mains, secondaries } = processCharacters(ranks);
    return {
      tag,
      normalizedTag: normalize(tag),
      countries,
      mains,
      secondaries,
      ranks: sortedRanks,
    };
  });
  return players;
};

export default processPlayers;
