import { MouseEvent, useCallback } from "react";
import { Coords } from "../../../shared/wordsquared/types";
import {
  gridSquareSizeSelector,
  useClientToGridCoords,
} from "../recoil/camera";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { cursorLocationAtomW2 } from "../recoil/game";
import { gridToTileCoords } from "../gridCanvasMath";

type CanvasClickHandler = (gridCoords: Coords) => void;

export const useGridCoordsHandler = (callback: CanvasClickHandler) => {
  const clientToGridCoords = useClientToGridCoords();
  return useCallback(
    (e: MouseEvent) => {
      callback(clientToGridCoords(e));
    },
    [callback, clientToGridCoords]
  );
};

export const useCursorClickHandler = () => {
  const squareSize = useRecoilValue(gridSquareSizeSelector);
  const setCursorLocation = useSetRecoilState(cursorLocationAtomW2);
  const onClickGrid = useCallback(
    (gridCoords: Coords) => {
      const tileCoords = gridToTileCoords(gridCoords, squareSize);
      setCursorLocation(tileCoords);
    },
    [setCursorLocation, squareSize]
  );

  return useGridCoordsHandler(onClickGrid);
};
