import { useCallback } from "react";
import { atom, useSetRecoilState } from "recoil";

export const cacheIdAtom = atom<number>({
  key: "util/cache-id",
  default: 0,
});

export const useBreakCache = () => {
  const setCacheId = useSetRecoilState(cacheIdAtom);
  return useCallback(() => {
    setCacheId(id => id + 1);
  }, [setCacheId]);
};
