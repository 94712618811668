import parseColorString from "../../shared/utils/parseColorString";

const LIGHTNESS_THRESHOLD = 128 * 3;
const DARK_TEXT = "#222";
const LIGHT_TEXT = "#ddd";

const getTextColorForBackground = (color: string) => {
  const [r, g, b] = parseColorString(color);
  const lightness = 0.7 * r + 1.6 * g + 0.7 * b;
  return lightness > LIGHTNESS_THRESHOLD ? DARK_TEXT : LIGHT_TEXT;
};

export default getTextColorForBackground;
