import { clamp, throttle } from "lodash";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

const withDraggableScroll = <P extends object>(
  WrappedComponent: React.ComponentType<P>
): React.ComponentType<P> => {
  return props => {
    const [isScrolling, setIsScrolling] = useState<boolean>(false);
    const [clientX, setClientX] = useState<number>(0);
    // const [clientY, setClientY] = useState<number>(0);
    const [scrollX, setScrollX] = useState<number>(0);
    // const [scrollY, setScrollY] = useState<number>(0);
    const ref = useRef<HTMLElement>(null);
    const updateScroll = useMemo(
      () =>
        throttle(x => {
          if (ref.current) {
            ref.current.scrollLeft = x;
          }
        }, 25),
      [ref]
    );

    const onMouseDown = useCallback((e: MouseEvent) => {
      setIsScrolling(true);
      setClientX(e.clientX);
      // setClientY(e.clientY);
    }, []);

    useEffect(() => {
      document.onmouseup = () => {
        setIsScrolling(false);
        setClientX(0);
        // setClientY(0);
      };
      return () => {
        document.onmouseup = null;
      };
    }, []);

    const onMouseMove = useCallback(
      (e: MouseEvent) => {
        if (ref.current && isScrolling) {
          setClientX(e.clientX);
          const newScrollX = clamp(
            scrollX - e.clientX + clientX,
            0,
            ref.current.scrollWidth - ref.current.clientWidth
          );
          setScrollX(newScrollX);
          updateScroll(newScrollX);
          // ref.current.scrollTop = scrollY - e.clientY + clientY;
          // setScrollY(scrollY - e.clientY + clientY);
          // setClientY(e.clientY);
          e.preventDefault();
        }
      },
      [
        ref,
        isScrolling,
        scrollX,
        clientX,
        updateScroll,
        // , scrollY, clientY
      ]
    );

    return (
      <WrappedComponent
        ref={ref}
        style={{ cursor: isScrolling ? "grabbing" : "grab" }}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        {...props}
      />
    );
  };
};

export default withDraggableScroll;
