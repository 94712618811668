import { W2InvalidPlayType } from "../../shared/wordsquared/w2Messages";

export enum NotifType {
  INVALID_PLAY = "invalid_play",
  // INVALID_USERNAME = "invalid_username",
}

export interface BaseNotif {
  type: string;
}

export interface InvalidPlayNotif {
  type: NotifType.INVALID_PLAY;
  reason: W2InvalidPlayType;
}

export type Notif = InvalidPlayNotif;
