import { ReactComponent as Australia } from "./flags/Flag_of_Australia.svg";
import { ReactComponent as Canada } from "./flags/Flag_of_Canada.svg";
import { ReactComponent as Chile } from "./flags/Flag_of_Chile.svg";
import { ReactComponent as England } from "./flags/Flag_of_England.svg";
import { ReactComponent as France } from "./flags/Flag_of_France.svg";
import { ReactComponent as Germany } from "./flags/Flag_of_Germany.svg";
import { ReactComponent as Japan } from "./flags/Flag_of_Japan.svg";
import { ReactComponent as Mexico } from "./flags/Flag_of_Mexico.svg";
import { ReactComponent as Netherlands } from "./flags/Flag_of_the_Netherlands.svg";
import { ReactComponent as NewZealand } from "./flags/Flag_of_New_Zealand.svg";
import { ReactComponent as Norway } from "./flags/Flag_of_Norway.svg";
import { ReactComponent as PuertoRico } from "./flags/Flag_of_Puerto_Rico.svg";
import { ReactComponent as Spain } from "./flags/Flag_of_Spain.svg";
import { ReactComponent as Sweden } from "./flags/Flag_of_Sweden.svg";
import { ReactComponent as Switzerland } from "./flags/Flag_of_Switzerland.svg";
import { ReactComponent as UnitedKingdom } from "./flags/Flag_of_the_United_Kingdom.svg";
import { ReactComponent as UnitedStates } from "./flags/Flag_of_the_United_States.svg";
import { ReactComponent as Wales } from "./flags/Flag_of_Wales.svg";

const COUNTRY_FLAG_MAP = {
  Australia,
  Canada,
  Chile,
  England,
  France,
  Germany,
  Japan,
  Mexico,
  Netherlands,
  Norway,
  Spain,
  Sweden,
  Switzerland,
  Wales,
  "New Zealand": NewZealand,
  "Puerto Rico": PuertoRico,
  "United Kingdom": UnitedKingdom,
  "United States": UnitedStates,
};

const Flag = ({ country, size = 24 }) => {
  const FlagSvg = COUNTRY_FLAG_MAP[country];
  if (!FlagSvg) alert(country);
  return <FlagSvg style={{ width: `${size}px`, height: `${size}px` }} />;
};

export default Flag;
