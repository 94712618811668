import { usePreview } from "react-dnd-preview";
import { DRAG_TYPES } from "shared/wordery/types";
import Tile from "./Tile";

const CustomDragPreview = () => {
  const preview = usePreview();
  console.log("🚀 / MyPreview / preview", preview);
  const { display, itemType, item, style } = preview;
  if (!display) {
    return <></>;
  }
  if (itemType === DRAG_TYPES.BOARD_TILE) {
    return (
      <div style={style}>
        <Tile isDraggingPreview tile={item.tile} source={item.source} />
      </div>
    );
  }
  return <></>;
};

export default CustomDragPreview;
