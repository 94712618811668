import { IS_DEV } from "shared/constants";

const API_BASE = IS_DEV ? "http://localhost:5000" : "";
// Run network.ps1 if this isn't working
// const API_BASE = IS_DEV ? "http://192.168.50.47:5000" : "";
export const API_URL = `${API_BASE}/api`;

const DEFAULT_OPTIONS: Partial<RequestInit> = {
  headers: {
    "Content-Type": "application/json",
  },
};

const MAX_TRIES = 5;

// Fetch with exponential backoff
export const fetchFromApi = (
  path: string,
  options?: RequestInit,
  maxTries: number = MAX_TRIES
): Promise<Response> => {
  let tries = 0;
  const retryFetch = new Promise<Response>((resolve, reject) => {
    const tryFetch = async () => {
      try {
        const result = await fetch(`${API_URL}${path}`, {
          ...DEFAULT_OPTIONS,
          ...options,
        });
        resolve(result);
        return;
      } catch (e) {
        if (tries > maxTries) {
          reject("API request failed");
          return;
        }
        setTimeout(tryFetch, 2 ** tries * 1000);
        tries++;
      }
    };
    tryFetch();
  });
  return retryFetch;
};
