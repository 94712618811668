// Client-to-server messages
export enum WSTunnelClientMessageType {
  LETTER = "letter",
}

type LetterClientMessage = {
  type: WSTunnelClientMessageType.LETTER;
  letter: string;
};

export type WSTunnelClientMessage = LetterClientMessage;

// Server-to-client messages
export enum WSTunnelServerMessageType {
  LIGHT = "light",
}

type WSLightServerMessage = {
  type: WSTunnelServerMessageType.LIGHT;
  value: boolean;
};

export type WSTunnelServerMessage = WSLightServerMessage;
