import { propEq } from "lodash/fp";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Background from "src/components/Background";
import Container from "src/components/Container";
import Loading from "src/components/Loading";
import { allPostsSelector } from "./recoil";
import marked from "marked";
import { ReactTitle } from "react-meta-tags";

const Post = () => {
  const posts = useRecoilValue(allPostsSelector);
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string; }>();
  const post = posts.find(propEq(["slug"], slug));
  if (!post) {
    navigate('/');
    return <div />;
  }
  const innerHtml = {
    __html: marked(post.content),
  };
  return (
    <>
      <ReactTitle title={`${post.title} | Leon's Website`} />
      <h1>{post.title}</h1>
      <div dangerouslySetInnerHTML={innerHtml} />
    </>
  );
};

const PostContainer = () => (
  <Background color="slategray">
    <Container>
      <Link to="/posts">&lt; Back to posts</Link>
      <React.Suspense fallback={<Loading />}>
        <Post />
      </React.Suspense>
    </Container>
  </Background>
);

export default PostContainer;
