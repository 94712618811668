import { atom, selector } from "recoil";
import { isValidWord } from "shared/words";
import { Rack, Word, WorderyBoard, WorderyDaily } from "shared/wordery/types";
import {
  getWords,
  getScoreFromWords,
  getEmptyBoard,
} from "shared/wordery/scoring";
import { reject } from "lodash/fp";

export const boardAtom = atom<WorderyBoard>({
  key: "wordery/board",
  default: [],
});

export const initialRackAtom = atom<Rack>({
  key: "wordery/initial-rack",
  default: [],
});

export const dailyAtom = atom<WorderyDaily | null>({
  key: "wordery/daily",
  default: null,
});

export const rackAtom = atom<Rack>({
  key: "wordery/rack",
  default: [],
});

export const gameIdAtom = atom<number>({
  key: "wordery/gameId",
  default: 0,
});

export const playerAtom = atom<string>({
  key: "wordery/player",
  default: "",
});

// TODO track
export const bestScoreAtom = atom<number>({
  key: "wordery/best-score",
  default: 0,
});

export const emptyBoardSelector = selector<WorderyBoard>({
  key: "wordery/empty-board",
  get: ({ get }) => {
    const board = get(boardAtom);
    const emptyBoard = getEmptyBoard(board);
    return emptyBoard;
  },
});

export const wordsSelector = selector<Word[]>({
  key: "wordery/words",
  get: ({ get }) => {
    const board = get(boardAtom);
    return [...getWords(board)];
  },
});

export const invalidWordsSelector = selector<string[]>({
  key: "wordery/invalid-words",
  get: ({ get }) => {
    const words = get(wordsSelector).map(word =>
      word.tiles.map(tile => tile.letter).join("")
    );
    const invalidWords = reject(isValidWord, words);
    return invalidWords;
  },
});

export const scoreSelector = selector<number>({
  key: "wordery/score",
  get: ({ get }) => {
    const words = get(wordsSelector);
    return getScoreFromWords(words);
  },
});
