import { useRecoilValue } from "recoil";
import styled from "styled-components";
import getTextColorForBackground from "../utils/getTextColorForBackground";
import { BASE_GRID_SQUARE_SIZE_PX } from "./constants";
// import { TILE_STROKE_COLOR } from "./useDraw";
import { currentRackAtom, pendingColorAtom } from "./recoil/game";
import { TILE_TEXT_COLOR } from "./hooks/useDraw";

const TilesContainer = styled.div`
  display: flex;
`;

const EmptyTile = styled.div`
  height: ${BASE_GRID_SQUARE_SIZE_PX - 12}px;
  width: ${BASE_GRID_SQUARE_SIZE_PX - 12}px;
  margin: 0 2px;
  border: 2px solid gray;
`;

const TileBox = styled(EmptyTile) <{ color: string; }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${0.75 * BASE_GRID_SQUARE_SIZE_PX}px;
  font-family: Consolas, sans-serif;
  /* color: ${TILE_TEXT_COLOR}; */
  color: ${({ color }) => getTextColorForBackground(color)};
  background-color: ${({ color }) => color};
  /* border: 2px solid {TILE_STROKE_COLOR}; */
  /* filter: drop-shadow(2px, 2px, 0, gray); */
  border: 2px solid rgba(1,1,1,0.2);
`;

const Tile = ({ letter }: { letter: string | null; }) => {
  const color = useRecoilValue(pendingColorAtom);
  if (!letter) {
    return <EmptyTile />;
  }
  return <TileBox color={color}>{letter}</TileBox>;
};

const Rack = () => {
  const rack = useRecoilValue(currentRackAtom);
  return <TilesContainer>
    {rack.map((letter, index) => <Tile key={(letter ?? "") + index} letter={letter} />)}
  </TilesContainer>;
};

export default Rack;