import _, { Dictionary, isEmpty } from "lodash";
import modulo from "../utils/modulo";
import { Coords } from "./types";

export const GRID_SIZE = 32;

export const getGridAreaIndexes = (x: number, y: number) => {
  return {
    bigX: Math.floor(x / GRID_SIZE),
    bigY: Math.floor(y / GRID_SIZE),
    lilX: modulo(x, GRID_SIZE),
    lilY: modulo(y, GRID_SIZE),
  };
};

export const getGridIndex = (
  bigX: number,
  bigY: number,
  lilX: number,
  lilY: number
): Coords => {
  return [bigX * GRID_SIZE + lilX, bigY * GRID_SIZE + lilY];
};

export const makeEmptyTiles = () =>
  Array(GRID_SIZE)
    .fill(0)
    .map(() => Array(GRID_SIZE));

type MapCallback2D<T, U> = (
  value: T,
  indices: [x: number, y: number],
  obj: Dictionary<T>
) => U;

const keyify = (x: number, y: number) => `${x},${y}`;

export class Sparse2DArray<T> {
  obj: Dictionary<T> = {};

  constructor(obj?: Dictionary<T>) {
    if (obj) {
      this.obj = { ...obj };
    }
  }

  clear() {
    this.obj = {};
  }

  set = (x: number, y: number, value: T) => {
    const key = keyify(x, y);
    this.obj[key] = value;
    return this;
  };

  /* In use cases that want immutability, such as Recoil, use this version */
  cloneSet = (x: number, y: number, value: T) => {
    const copy = new Sparse2DArray<T>(this.obj);
    copy.set(x, y, value);
    return copy;
  };

  get(x: number, y: number): T | undefined {
    const key = keyify(x, y);
    return this.obj[key];
  }

  has(x: number, y: number): boolean {
    const key = keyify(x, y);
    return key in this.obj;
  }

  delete(x: number, y: number): boolean {
    const key = keyify(x, y);
    return delete this.obj[key];
  }

  /* In use cases that want immutability, such as Recoil, use this version */
  cloneDelete = (x: number, y: number) => {
    const copy = new Sparse2DArray<T>(this.obj);
    copy.delete(x, y);
    return copy;
  };

  keys = (): Coords[] => {
    return this.map((_, key) => key);
  };

  values = (): T[] => {
    return Object.values(this.obj);
  };

  isEmpty = (): boolean => {
    return isEmpty(this.obj);
  };

  map<U>(fn: MapCallback2D<T, U>): U[] {
    return _.map<Dictionary<T>, U>(
      this.obj,
      (value: T, key: string, obj: Dictionary<T>) => {
        const [x, y] = key.split(",").map(n => parseInt(n));
        return fn(value, [x, y], obj);
      }
    );
  }

  forEach(fn: MapCallback2D<T, void>) {
    return _.map<Dictionary<T>, void>(
      this.obj,
      (value: T, key: string, obj: Dictionary<T>) => {
        const [x, y] = key.split(",").map(n => parseInt(n));
        fn(value, [x, y], obj);
      }
    );
  }
}
