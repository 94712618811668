import { useCallback } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  cursorLocationAtomW2,
  deleteCursorTargetSelector,
  pendingMoveAtom,
  useResetCurrentRack,
} from "../recoil/game";

const useOnDeletePendingMove = () => {
  const resetPendingMove = useResetRecoilState(pendingMoveAtom);
  const resetRack = useResetCurrentRack();
  const cursorTarget = useRecoilValue(deleteCursorTargetSelector);
  const setCursorLocation = useSetRecoilState(cursorLocationAtomW2);
  return useCallback(() => {
    // If there are any pending tiles
    if (cursorTarget) {
      resetPendingMove();
      resetRack();
      setCursorLocation(cursorTarget);
    }
  }, [resetPendingMove, resetRack, cursorTarget, setCursorLocation]);
};

export default useOnDeletePendingMove;
