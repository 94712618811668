import { GridAreaType, Letter, PlayTile } from "./types";

// Client-to-server messages
export enum W2ClientMessageType {
  SUBMIT_PLAY = "submit_play",
  REFRESH_RACK = "refresh_rack",
}

interface W2UserMessage {
  user: string;
}

export interface W2SubmitPlayClientMessage extends W2UserMessage {
  type: W2ClientMessageType.SUBMIT_PLAY;
  tiles: PlayTile[];
  color: string;
}

export interface W2RefreshRackClientMessage extends W2UserMessage {
  type: W2ClientMessageType.REFRESH_RACK;
}

export type W2ClientMessage =
  | W2SubmitPlayClientMessage
  | W2RefreshRackClientMessage;

// Server-to-client messages
export enum W2ServerMessageType {
  WELCOME = "welcome",
  BOARD_UPDATE = "board_update",
  INVALID_PLAY = "invalid_play",
  RACK_UPDATE = "rack_update",
}

export interface W2WelcomeMessage {
  type: W2ServerMessageType.WELCOME;
  playerId: string;
}

export interface W2BoardUpdateMessage {
  type: W2ServerMessageType.BOARD_UPDATE;
  gridAreas: GridAreaType[];
}

export interface W2InvalidPlayMessage {
  type: W2ServerMessageType.INVALID_PLAY;
  reason: W2InvalidPlayType;
}

export interface W2RackUpdateMessage {
  type: W2ServerMessageType.RACK_UPDATE;
  rack: Letter[];
}

export type W2ServerMessage =
  | W2WelcomeMessage
  | W2BoardUpdateMessage
  | W2InvalidPlayMessage
  | W2RackUpdateMessage;

export enum W2InvalidPlayType {
  ILLEGAL_WORD = "illegal_word",
  ILLEGAL_USERNAME = "illegal_username",
  INVALID_COLOR = "invalid_color",
}
