import ws from "ws";

export type Letter =
  | "A"
  | "B"
  | "C"
  | "D"
  | "E"
  | "F"
  | "G"
  | "H"
  | "I"
  | "J"
  | "K"
  | "L"
  | "M"
  | "N"
  | "O"
  | "P"
  | "Q"
  | "R"
  | "S"
  | "T"
  | "U"
  | "V"
  | "W"
  | "X"
  | "Y"
  | "Z";

export type PlayTile = {
  letter: Letter;
  x: number;
  y: number;
};

export type BoardTile = {
  letter: Letter;
  user: string;
  timestamp: number;
  color?: string;
};

export type GridAreaType = {
  areaX: number;
  areaY: number;
  tiles: (BoardTile | null)[][];
};

export type Coords = [number, number];

export enum CursorDirection {
  RIGHT = "right",
  DOWN = "down",
}

export interface PlayerState {
  rack: Letter[];
  socket: ws;
}
