import React, { SyntheticEvent } from "react";
import styled from "styled-components";

const TILE_SIZE = 69;

interface TileProps {
  value: string | null;
  onMouseUp: (event: SyntheticEvent) => void;
  onMouseDown: (event: SyntheticEvent) => void;
  onHover: () => void;
  highlighted: boolean;
}

const TileContainer = styled.div<{ empty: boolean; highlighted: boolean }>`
  display: flex;
  position: relative;
  height: ${TILE_SIZE}px;
  width: ${TILE_SIZE}px;
  background-image: ${props =>
    props.empty
      ? "none"
      : props.highlighted
      ? 'url("./apple_highlighted.png")'
      : 'url("./apple.png")'};
  background-repeat: no-repeat;
  background-size: 60px 60px;
  background-position: center;
  background-color: transparent;
  font-size: 32px;
  font-family: "Consolas", sans-serif;
  justify-content: center;
  align-items: center;
  color: #222;
  cursor: default;
  /* background-color: Beige; */
  /* border: 1px solid black; */
`;

const Tile: React.FC<TileProps> = ({
  value,
  highlighted,
  onHover,
  onMouseUp,
  onMouseDown,
}) => {
  return (
    <TileContainer
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseOver={onHover}
      empty={!value}
      highlighted={highlighted}
    >
      {value}
    </TileContainer>
  );
};

export default Tile;
