import { useCallback } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { cursorLocationAtomW2, gridTilesS2ASelector } from "../recoil/game";
import {
  cameraLocationSquaresSelector,
  currentDimensionsInSquaresSelector,
} from "../recoil/camera";

const BORDER_DISTANCE_TO_SCROLL = 1.5;

const useStepCursor = (skipExisting: boolean = false) => {
  const [cursorLocation, setCursorLocation] =
    useRecoilState(cursorLocationAtomW2);
  const gridTiles = useRecoilValue(gridTilesS2ASelector);
  const [[cameraX, cameraY], setCameraLocationSquares] = useRecoilState(
    cameraLocationSquaresSelector
  );
  const [widthSquares, heightSquares] = useRecoilValue(
    currentDimensionsInSquaresSelector
  );

  const isOffScreen = useCallback(
    (x: number, y: number, cx: number, cy: number) => {
      return (
        Math.abs(x - cx) > widthSquares / 2 - BORDER_DISTANCE_TO_SCROLL ||
        Math.abs(y - cy) > heightSquares / 2 - BORDER_DISTANCE_TO_SCROLL
      );
    },
    [widthSquares, heightSquares]
  );

  const stepCursor = useCallback(
    ([dx, dy]) => {
      let [x, y] = cursorLocation;
      let [cx, cy] = [cameraX, cameraY];
      do {
        x += dx;
        y += dy;
        if (isOffScreen(x, y, cx, cy)) {
          cx += dx;
          cy += dy;
          if (isOffScreen(x, y, cx, cy)) {
            // We got the cursor way off screen somehow, possibly by resizing the window
            setCameraLocationSquares([x, y]);
            return;
          }
        }
      } while (skipExisting && gridTiles.has(x, y));
      setCursorLocation([x, y]);
      setCameraLocationSquares([cx, cy]);
    },
    [
      setCursorLocation,
      setCameraLocationSquares,
      gridTiles,
      cursorLocation,
      cameraX,
      cameraY,
      isOffScreen,
      skipExisting,
    ]
  );
  return stepCursor;
};

export default useStepCursor;
