import { SyncAlt } from "@styled-icons/fa-solid";
import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { startRotation } from "src/utils/rotator";
import styled from "styled-components";

export const NAVBAR_HEIGHT = 32;
const NAVBAR_COLOR = '#333';

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  height: ${NAVBAR_HEIGHT}px;
  padding-bottom: 4px;
  background-color: ${NAVBAR_COLOR};
`;

const Item = styled(Link)`
  color: white;
  font-weight: 500;
  margin: 0 12px;
`;

const RotateButton = styled(SyncAlt).attrs({ size: 16, color: "white" })`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
`;

const DropdownContents = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: ${NAVBAR_HEIGHT};
  background-color: ${NAVBAR_COLOR};
  padding: 12px;
  border-radius: 0 0 8px 8px;
  z-index: 1; // used to get the dropdown to appear above the WS canvas
  a {
    padding: 8px 0;
    margin: 0px;
  }
`;

interface DropdownProps {
  label: string;
}
const Dropdown: React.FC<DropdownProps> = ({ children, label }) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const onMouseEnter = useCallback(() => {
    setIsOpen(true);
  }, []);
  const onMouseLeave = useCallback(() => {
    setIsOpen(false);
  }, []);
  return (
    <DropdownContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Item to='hello' ref={linkRef} >{label}</Item>
      {isOpen && <DropdownContents>{children}</DropdownContents>}
    </DropdownContainer>
  );
};

const NavBar = () => (
  <NavBarContainer>
    {/* <Item to="/">Home</Item> */}
    <Item to="/">About</Item>
    <Item to="/posts">Posts</Item>
    <Dropdown label="Games">
      <Item to="/ws">WordSquared</Item>
      <Item to="/wordery">Wordery</Item>
      <Item to="/boxing">Boxing</Item>
      <Item to="/anagrams">Anagram Trainer</Item>
    </Dropdown>
    <Item to="/ssbmrank">SSBM Rankings</Item>
    <RotateButton onClick={startRotation} />
  </NavBarContainer>
);

export default React.memo(NavBar);
