import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  boardAtom,
  invalidWordsSelector,
  playerAtom,
  scoreSelector,
  useKeyboardCallback,
  useLoadSavedBoard,
  useSubmitScore,
} from "./recoil";
import BoardCell, { CELL_SIZE } from "./BoardCell";
import ActionButton from "src/components/ActionButton";
import { useCallback } from "react";
import { useUpdateLeaderboard } from "./recoil/leaderboard";

interface GridProps {
  readonly columns: number;
  readonly rows: number;
}

const BoardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, ${CELL_SIZE}px);
  grid-template-rows: repeat(${props => props.rows}, ${CELL_SIZE}px);
  grid-gap: 2px;
  background-color: #948575;
  border-radius: 2px;
  padding: 2px;
`;

const ScoreContainer = styled.div<{ invalid: boolean }>`
  ${props => (props.invalid ? "text-decoration: line-through" : "")}
`;

const Score = () => {
  const score = useRecoilValue(scoreSelector);
  const invalidWords = useRecoilValue(invalidWordsSelector);
  return (
    <ScoreContainer invalid={!!invalidWords.length}>
      Score: {score}
    </ScoreContainer>
  );
};

const InvalidWords = () => {
  const invalidWords = useRecoilValue(invalidWordsSelector);
  if (!invalidWords.length) return null;
  return <div>Invalid words: {invalidWords.join(", ")}</div>;
};

const LoadBestButton = () => {
  const loadBest = useLoadSavedBoard();
  return <ActionButton onClick={loadBest} value="Load best score" />;
};

const SubmitButton = () => {
  const submit = useSubmitScore();
  const update = useUpdateLeaderboard();
  const invalidWords = useRecoilValue(invalidWordsSelector);
  const onSubmit = useCallback(() => {
    submit();
    setTimeout(() => update(), 100);
  }, [submit, update]);
  return (
    <ActionButton
      onClick={onSubmit}
      disabled={!!invalidWords.length}
      value="Submit score"
    />
  );
};

const Board = () => {
  const board = useRecoilValue(boardAtom);
  const [playerName, setPlayerName] = useRecoilState(playerAtom);
  useKeyboardCallback();
  return (
    <BoardContainer>
      <Grid rows={board.length} columns={board[0].length}>
        {board.map((row, rowIdx) =>
          row.map((cell, colIdx) => (
            <BoardCell
              key={`${rowIdx},${colIdx}`}
              row={rowIdx}
              col={colIdx}
              boardCell={cell}
            />
          ))
        )}
      </Grid>
      <Score />
      <InvalidWords />
      <div>
        Name:&nbsp;
        <input
          type="text"
          onChange={e => setPlayerName(e.target.value)}
          value={playerName}
        />
      </div>
      <span>
        <SubmitButton />
        <LoadBestButton />
      </span>
    </BoardContainer>
  );
};

export default Board;
