import { set } from "lodash/fp";
import { useCallback } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { CursorDirection, Letter } from "../../../shared/wordsquared/types";
import useStepCursor from "./useStepCursor";
import {
  currentRackAtom,
  cursorDirectionAtomW2,
  cursorLocationAtomW2,
  gridTilesS2ASelector,
  pendingMoveAtom,
} from "../recoil/game";

const CURSOR_TO_MOVEMENT = {
  [CursorDirection.RIGHT]: [1, 0],
  [CursorDirection.DOWN]: [0, -1],
};

const useOnLetterInput = () => {
  const setPendingMove = useSetRecoilState(pendingMoveAtom);
  const cursorLocation = useRecoilValue(cursorLocationAtomW2);
  const gridTiles = useRecoilValue(gridTilesS2ASelector);
  const cursorDirection = useRecoilValue(cursorDirectionAtomW2);
  const stepCursor = useStepCursor(true);
  const [currentRack, setCurrentRack] = useRecoilState(currentRackAtom);

  return useCallback(
    (letter: Letter) => {
      const [x, y] = cursorLocation;
      // If there's already a tile here, do nothing
      if (gridTiles.has(x, y)) {
        return;
      }
      const rackIndex = currentRack.findIndex(c => c === letter);
      if (rackIndex === -1) {
        return;
      }
      setCurrentRack(set(rackIndex, null));
      setPendingMove(move => move.cloneSet(x, y, letter));
      stepCursor(CURSOR_TO_MOVEMENT[cursorDirection]);
    },
    [
      cursorDirection,
      cursorLocation,
      gridTiles,
      setPendingMove,
      stepCursor,
      currentRack,
      setCurrentRack,
    ]
  );
};

export default useOnLetterInput;
