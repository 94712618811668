import { Recycle } from "@styled-icons/fa-solid";
import styled from "styled-components";
import { W2ClientMessageType } from "../../shared/wordsquared/w2Messages";
import { hoverableButtonCss } from "./buttonHover";
import { useFocusCanvas } from "./hooks/useFocus";
import { w2Socket } from "./w2Websocket";

const Button = styled.div`
  margin: 0 4px;
  // The icon is kinda off-center
  padding: 8px;
  cursor: pointer;
  ${hoverableButtonCss}
`;

const RefreshButton = () => {
  const focusCanvas = useFocusCanvas();
  const onClickRefresh = () => {
    const message = {
      type: W2ClientMessageType.REFRESH_RACK,
    };
    w2Socket.send(JSON.stringify(message));
    focusCanvas();
  };
  return <Button onClick={onClickRefresh}>
    <Recycle height="22" />
  </Button>;

};

export default RefreshButton;