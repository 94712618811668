import { memo } from "react";
import { useRecoilValue } from "recoil";
import { dailyAtom } from "./recoil";

const DailyHeading = () => {
  const daily = useRecoilValue(dailyAtom);
  if (!daily) return <></>;
  const date = new Date(daily.date);
  return <h3>Daily puzzle for {date.toLocaleDateString()}</h3>;
};

export default memo(DailyHeading);
