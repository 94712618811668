export interface ChatMessage {
  text: string;
  author: string;
}

// Client-to-server messages
export enum WSChatClientMessageType {
  NEW_MESSAGE = "new_message",
}

type LetterClientMessage = {
  type: WSChatClientMessageType.NEW_MESSAGE;
  message: ChatMessage;
};

export type WSChatClientMessage = LetterClientMessage;

// Server-to-client messages
export enum WSChatServerMessageType {
  MESSAGES = "messages",
}

type WSLightServerMessage = {
  type: WSChatServerMessageType.MESSAGES;
  chatMessages: ChatMessage[];
};

export type WSChatServerMessage = WSLightServerMessage;
