import _ from "lodash";
import normalize from "src/utils/normalize";
import processPlayers from "./playerProcessor";
import ranksJson from "./ranks.json";
import { RanksJson } from "./types";

const RANKS_JSON = ranksJson as RanksJson;
const RANKS = RANKS_JSON.map(ranking => {
  return {
    ...ranking,
    ranks: ranking.ranks.map(rank => ({
      ...rank,
      searchValue: [
        normalize(rank.tag),
        normalize(rank.characters.join(",")),
        normalize(rank.country),
      ].join("|"),
    })),
  };
});
export const PLAYERS = processPlayers(RANKS);
export const RANKINGS = _.sortBy(RANKS, rank => rank.name);
