import Background from "../components/Background";
import Container from "../components/Container";
import { ReactTitle } from "react-meta-tags";

const About = () => {
  return (
    <Background>
      <ReactTitle title="Home | Leon's Website" />
      <Container>
        <h1>About Me</h1>
        <div>
          Hello! I'm Leon Zhou, also known as ycz. I'm a web developer, gamer,
          puzzle enthusiast, and dabbler in many other pursuits.
          <br />
          <br />I grew up in San Diego, California, and majored in math at MIT.
          In 2014, after graduating, I moved to San Francisco, where I live now,
          and worked at mobile gaming studio Pocket Gems for several years as a
          web and mobile developer. I later joined self-driving car startup
          Cruise, also in a web development role.
        </div>
        <h2>Programming</h2>
        <div>
          While I've had job experience as a programmer on web backends and iOS,
          I've primarily been a frontend dev for the last few years. I've mainly
          used React and TypeScript in the recent past, and I like both a lot. I
          enjoy messing around with nice new frameworks like Recoil, and also
          doing dumb stuff with websites (try clicking that button in the top
          right).
          <br />
          <br />
          Most recently, I attended{" "}
          <a href="recurse.com" target="_blank" rel="noreferrer">
            Recurse Center
          </a>{" "}
          in early 2022, where among other things I worked on{" "}
          <a href="/ws">a clone of WordSquared</a>, a defunct browser-based MMO
          word game, which I'm quite proud of! I also wrote the custom slippi-js
          script mentioned below in the SSBM section.
        </div>
        <h2>Gaming</h2>
        <h3>SSBM</h3>
        <div>
          Super Smash Bros. Melee is my favorite video game of all time, and has
          been for over fifteen years now. I've been an active member of the
          online and in-person community for most of that time - I went to my
          first tournament in 2006 and have been attending them on and off since
          then.
          <br />
          <br />
          As a competitive player, I've been{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ssbwiki.com/NorCal_Power_Rankings"
          >
            ranked in Northern California
          </a>{" "}
          a number of times, peaking at #15 in 2019. I enjoy doing things in the
          game that haven't been done before, whether that be through my
          playstyle or exploring aspects of the game less popular amongst the
          competitive crowd. Some of my recent "work":
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=UVhURfrQcFY"
              >
                A traditional (but stylish) combo video
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=q8m_1_UcXSc"
              >
                Beating Adventure Mode blindfolded
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=nMma8otuPVI"
              >
                A "combo video"
              </a>{" "}
              generated using{" "}
              <a
                href="https://gist.github.com/ycz/e3b0d6a748d5b858c2661321e195b988"
                target="_blank"
                rel="noreferrer"
              >
                a slippi-js script I wrote
              </a>
            </li>
            <li>
              A number of training mode combos; my favorites are{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=qzZeEVghUsg"
              >
                this Roy combo
              </a>
              ,{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=m2FTbUvh4Fk"
              >
                this Young Link combo
              </a>
              , and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/ycz6/status/1251725732994138113"
              >
                this Falco combo
              </a>
              , which won a training mode combo contest for a cash prize.
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=kaUxgx-oFKs"
              >
                Commentating this Giant Melee showmatch
              </a>
            </li>
            <li>
              Posting all sorts of fun clips on{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/ycz6/media"
              >
                Twitter
              </a>
            </li>
          </ul>
        </div>
        <h3>Other games I enjoy</h3>
        <ul>
          <li>Threes</li>
          <li>Teamfight Tactics</li>
          <li>Slay the Spire</li>
          <li>Babble Royale</li>
          <li>Guilty Gear: Strive</li>
          <li>Dragon Ball FighterZ</li>
          <li>Various rhythm games, currently Beat Saber</li>
          <li>
            Various indie single player games, most recently Disco Elysium
          </li>
          <li>
            Various puzzle games, including Baba Is You and Stephen's Sausage
            Roll, both of which I still have yet to finish
          </li>
        </ul>
        <h2>Puzzles</h2>
        I'm a member of ✈✈✈ Galactic Trendsetters ✈✈✈, a team which participates
        in the{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://web.mit.edu/puzzle/www/"
        >
          MIT Mystery Hunt
        </a>{" "}
        and other puzzle hunts. We also run the annual-ish{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://galacticpuzzlehunt.com/"
        >
          Galactic Puzzle Hunt
        </a>
        , and organized the 2021 Mystery Hunt after winning the previous year.
        <br />
        <br />
        I'm not an expert in any particular puzzle genre, but I do like word
        puzzles a lot - I've been into Bananagrams, online Scrabble, Words With
        Friends at various points in my life and am working on{" "}
        <a href="/wordery">a Boggle-inspired word game</a> on this very website.
        I like crosswords (American and cryptic) and logic puzzles, but am not
        very good at them. My favorite puzzly video games are The Witness and
        Baba Is You.
      </Container>
    </Background>
  );
};

export default About;
