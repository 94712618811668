import { atom } from "recoil";

type SelectedCell = [number, number] | null;

export const selectedCellAtom = atom<SelectedCell>({
  key: "wordery/controls/selected-cell",
  default: null,
});

export enum CursorDirection {
  RIGHT = "right",
  DOWN = "down",
}

export const cursorDirectionAtom = atom<CursorDirection>({
  key: "wordery/controls/cursor-direction",
  default: CursorDirection.RIGHT,
});
