import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import PlayerCard from "./PlayerCard";
import { PLAYERS } from "./ranks";

const CardTooltip = styled(ReactTooltip)`
  opacity: 0.99 !important; /* this library is kind of dumb */
`;

const Tooltips = React.memo(() => {
  return (
    <>
      {Object.values(PLAYERS).map(player => (
        <CardTooltip
          id={`tooltip-${player.tag}`}
          key={`tooltip-${player.tag}`}
          effect="solid"
          textColor="black"
          backgroundColor="none"
          clickable={true}
          bodyMode
        >
          <PlayerCard player={player} />
        </CardTooltip>
      ))}
    </>
  );
});

export default Tooltips;
