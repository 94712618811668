import { TileLocation } from "shared/wordery/types";
import { concat, isEqual, pipe, pullAt, set, __ } from "lodash/fp";
import { useCallback } from "react";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  boardAtom,
  dailyAtom,
  emptyBoardSelector,
  gameIdAtom,
  initialRackAtom,
  playerAtom,
  rackAtom,
} from "./gameState";
import { fetchFromApi } from "src/utils/api";
import { useNavigate } from "react-router-dom";
import { loadSavedBoard, saveBoard } from "../savedBoards";
import { selectedCellAtom } from "./controls";

export const useLoadGame = (gameId: string) => {
  const navigate = useNavigate();
  return useRecoilCallback(
    ({ set }) =>
      async () => {
        const res = await fetchFromApi(`/wordery/game/${gameId}`);
        let json = await res.json();
        if (!json.id) {
          const newRes = await fetchFromApi(`/wordery/game/new`);
          json = await newRes.json();
        }
        const {
          id,
          params: { rack, board },
          daily = null,
        } = json;
        navigate(`/wordery/${id}`);
        set(gameIdAtom, id);
        set(boardAtom, board);
        set(rackAtom, rack);
        set(initialRackAtom, rack);
        set(dailyAtom, daily);
      },
    [gameId]
  );
};

export const useDropTileOnRack = () => {
  const setRack = useSetRecoilState(rackAtom);
  const setBoard = useSetRecoilState(boardAtom);
  return useCallback(
    (tile, source) => {
      if (source.location === TileLocation.Board) {
        const {
          position: [sourceRow, sourceCol],
        } = source;
        setBoard(set([sourceRow, sourceCol, "contents"], null));
        setRack(concat(__, [tile]));
      }
    },
    [setRack, setBoard]
  );
};

export const useDropTileOnBoard = () => {
  const setBoard = useSetRecoilState(boardAtom);
  const setRack = useSetRecoilState(rackAtom);
  const [selectedCell, setSelectedCell] = useRecoilState(selectedCellAtom);
  return useCallback(
    (tile, source, targetRow, targetCol, contents) => {
      if (source.location === TileLocation.Board) {
        const {
          position: [sourceRow, sourceCol],
        } = source;
        setBoard(
          pipe(
            set([targetRow, targetCol, "contents"], tile),
            set([sourceRow, sourceCol, "contents"], contents)
          )
        );
      } else if (source.location === TileLocation.Rack) {
        setBoard(set([targetRow, targetCol, "contents"], tile));
        if (contents === null) {
          setRack(pullAt([source.position]));
        } else {
          setRack(set([source.position], contents));
        }
      }
      if (isEqual(selectedCell, [targetRow, targetCol])) {
        setSelectedCell(null);
      }
    },
    [setBoard, setRack, selectedCell, setSelectedCell]
  );
};

export const useSubmitScore = () => {
  const board = useRecoilValue(boardAtom);
  const rack = useRecoilValue(rackAtom);
  const player = useRecoilValue(playerAtom);
  const gameId = useRecoilValue(gameIdAtom);
  return useCallback(async () => {
    await fetchFromApi(`/wordery/score/${gameId}/${player}`, {
      method: "POST",
      body: JSON.stringify({
        board,
      }),
    });
    saveBoard(gameId, board, rack);
  }, [board, rack, player, gameId]);
};

export const useClearBoard = () => {
  const setRack = useSetRecoilState(rackAtom);
  const setBoard = useSetRecoilState(boardAtom);
  const initialRack = useRecoilValue(initialRackAtom);
  const emptyBoard = useRecoilValue(emptyBoardSelector);
  return useCallback(() => {
    setBoard(emptyBoard);
    setRack(initialRack);
  }, [setRack, setBoard, initialRack, emptyBoard]);
};

export const useLoadSavedBoard = () => {
  const setRack = useSetRecoilState(rackAtom);
  const setBoard = useSetRecoilState(boardAtom);
  const gameId = useRecoilValue(gameIdAtom);
  return useCallback(() => {
    const savedBoard = loadSavedBoard(gameId);
    if (!savedBoard) {
      return;
    }
    setRack(savedBoard.rack);
    setBoard(savedBoard.board);
  }, [setRack, setBoard, gameId]);
};
